import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CustomDialog from "../Utils/CustomDialog"
import CustomModal from "../Utils/CustomModal";
import CustomAlert from "../Utils/CustomAlert";
import DashboardLayout from '../Shared/DashboardLayout';
import * as model from "../Models/Index";
import { Divider, TextField, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SaveAlt, Edit, Delete } from "@mui/icons-material";
import { useCompoundingFormDataMutation, useGetCompoundingQuery, useCompoundingToggleMutation, useCompoundingDeleteMutation } from "../Middlewares/Redux/compoundingApi";
import * as common from "../Utils/Common";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Compounding() {
    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);
    const [values, setValues] = useState(model.compounding.CompoundingModel);
    const [compoundingFormData] = useCompoundingFormDataMutation();
    const [compoundingToggle] = useCompoundingToggleMutation();
    const [compoundingDelete] = useCompoundingDeleteMutation();
    const { data, refetch, status } = useGetCompoundingQuery();
    const alertRef = useRef(null);
    const dialogRef = useRef(null);
    const modalRef = useRef(null);

    const OnOpenModal = () => {
        setValues(model.compounding.CompoundingModel);
        modalRef.current.toggleModal();
    }

    const OnEditRequest = (item) => {
        setValues(item);
        modalRef.current.toggleModal();
    }

    const OnDeleteRequest = (item, name) => {
        setBusy(true);
        let newMessage = (<><b className='mb-4'>{name}, will be deleted.</b> <br /> <em className='text-danger'>Are you sure?</em></>);

        setMessages(newMessage);
        setValues(item);
        setBusy(false);
        dialogRef.current.toggleDialog();
    }

    const OnDelete = async () => {
        setBusy(true);

        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await compoundingDelete(values.id);
            setBusy(false);
            refetch();
            setMessages(response?.data?.message);
            dialogRef.current.toggleDialog();
            alertRef.current.toggleAlert();
        })
    }

    const OnToggle = (id) => {
        setBusy(true);
        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await compoundingToggle(id);
            setBusy(false);
            refetch();

            setMessages(response?.data?.message);
            alertRef.current.toggleAlert();
        });
    }

    const SaveData = () => {
        setBusy(true);

        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await compoundingFormData(values);
            setBusy(false);
            refetch();

            setMessages(response?.data?.message);
            setValues(model.compounding.CompoundingModel);
            modalRef.current.toggleModal();
            alertRef.current.toggleAlert();
        })
    }

    let count = 1;

    return (
        <DashboardLayout>
            <div className='container mb-5' style={{ marginTop: '110px' }}>
                <div className='row mb-5'>
                    <div className='col-md-6'><h2>Manage Compounding</h2></div>
                    <div className='col-md-6 text-right'><button disabled={isBusy} className='btn btn-success' onClick={OnOpenModal}>New</button></div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S/N</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Compound</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row) => (
                                <StyledTableRow key={row.Id}>
                                    <StyledTableCell component="th" scope="row"> {count++}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.name}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.compound}</StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        <FormControlLabel control={<Switch color='success' disabled={isBusy} checked={row.isActive} onChange={() => OnToggle(row.id)} />} label={row.isActive ? "Active" : "Disabled"} />
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        <Edit disabled={isBusy} style={{ cursor: 'pointer' }} className='mr-4' color="primary" onClick={() => OnEditRequest(row)} />
                                        <Delete disabled={isBusy} style={{ cursor: 'pointer' }} color="error" onClick={() => OnDeleteRequest(row, row.name)} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>

                <CustomModal ref={modalRef} width={'600px'} height={'200px'}>
                    <div className='d-flex flex-wrap align-items-center justify-content-between mb-2'>
                        <h4>Compounding</h4>
                        <button onClick={() => modalRef.current.toggleModal()} className='btn btn-close'>Close</button>
                    </div>
                    <Divider />

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                        <div className="col-md-12">
                            <TextField value={values.name} onChange={(e) => { setValues({ ...values, name: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Name" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField type="number" value={values.compound} onChange={(e) => { setValues({ ...values, compound: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Compound" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <LoadingButton
                                color="primary"
                                onClick={() => SaveData()}
                                loading={isBusy}
                                disabled={isBusy}
                                loadingPosition="start"
                                startIcon={<SaveAlt />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </div>

                    </div>

                </CustomModal>

                <CustomDialog ref={dialogRef} >
                    <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                    <div className='row'>
                        <div className='col-md-6'><button className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                        <div className='col-md-6 text-right'>
                            <LoadingButton
                                color="error"
                                onClick={() => OnDelete()}
                                loading={isBusy}
                                disabled={isBusy}
                                loadingPosition="start"
                                startIcon={<Delete />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 2, py: 1 }}
                            >
                                <span>Yes</span>
                            </LoadingButton>
                        </div>
                    </div>
                </CustomDialog>
                <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

            </div>
        </DashboardLayout>
    );
}