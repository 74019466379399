import { apiSlice } from './Config/BasicSetup'

export const plansSettingApi = apiSlice.injectEndpoints({
    reducerPath: "plansSettingApi",
    tagTypes: ['Plans'],
    endpoints: (build) => ({

        plansFetchAll: build.query({
            query: () => `plans/get-all`,
            invalidatesTags: ['Plans']
        }),

        plansFormData: build.mutation({
            query: (data) => ({
                url: `plans/form-data`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['Plans']
        }),

        plansToggle: build.mutation({
            query: (id) => ({
                url: `plans/toggle?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Plans']
        }),

        plansDelete: build.mutation({
            query: (id) => ({
                url: `plans/delete?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Plans']
        }),
        
    }),
    overrideExisting: true,
})

export const {
    usePlansFetchAllQuery,
    usePlansFormDataMutation,
    usePlansToggleMutation,
    usePlansDeleteMutation,
} = plansSettingApi;