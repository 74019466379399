import { apiSlice } from './Config/BasicSetup'

export const walletAddressApi = apiSlice.injectEndpoints({
    reducerPath: "walletAddressApi",
    tagTypes: ['WalletAddress'],
    endpoints: (build) => ({

        getWalletAddress: build.query({
            query: () => `walletAddress/get-all`,
            invalidatesTags: ['WalletAddress']
        }), 

        getUserWalletAddress: build.query({
            query: () => `WalletAddress/get-user-wallet-address`,
            invalidatesTags: ['WalletAddress']
        }),

        // getSelectedUserWalletAddress: build.query({
        //     query: (userId) => `WalletAddress/get-selected-user-wallet-address?userId=${userId}`,
        //     invalidatesTags: ['Wallet']
        // }),

        getSelectedUserWalletAddress: build.mutation({
            query: (userId) => ({
                url: `WalletAddress/get-selected-user-wallet-address?userId=${userId}`,
                method: 'GET',
            }),
            invalidatesTags: ['Wallet']
        }),

        saveWalletAddress: build.mutation({
            query: (data) => ({
                url: `WalletAddress/save-data`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['Wallet']
        })
    }),
    overrideExisting: true,
})
  
export const {
    useGetWalletAddressQuery,
    useGetUserWalletAddressQuery,
    useGetSelectedUserWalletAddressMutation,
    useSaveWalletAddressMutation,
} = walletAddressApi;