// export let CurrentUserDto = {
//     fullName: null,
//     userName: null,
//     role:null,
//     token:null,
//     email:null,
//     isAuthenticated:Boolean,
// }


export let CurrentUserModel = {
    id:null,
    userName:null,
    password:null,
    fullName: null,
    email:null,
    phoneNumber:null,
    bitcoinWalletAddress:null,
    secreteQuestion:null,
    secreteQuestionAnswer:null,
    role:null,
    membership:null,
    referralId:null,
}