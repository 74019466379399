import DashboardLayout from "../Shared/DashboardLayout";
import { useState } from "react";
import { TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AttachMoneySharp from '@mui/icons-material/AttachMoneySharp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import * as common from "../Utils/Common";
import { useInvestFormDataMutation, useGetInvestmentsQuery } from "../Middlewares/Redux/investApi";
import { usePlansFetchAllQuery } from "../Middlewares/Redux/plansSettingApi";
import { useLayoutEffect } from "react";

let fundWalletDto = {
    Amount: 0,
    PlanId: null,
    Min: 0,
    Max: 0,
}

export default function Invest() {
    const [fundWallet, setFundWallet] = useState(fundWalletDto);

    const [isModalOpen, setOpenModal] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [showInvestmentWalletAddress, setShowInvestmentWalletAddress] = useState(false);
    // const [isFlaged, setFlaged] = useState(false);
    const [amount, setAmount] = useState(0);
    const [isBitcoinWallet, setBitcoinWallet] = useState(false);
    const [isOtherWallet, setOtherWallet] = useState(false);
    const [paymentDetail, setPaymentDetail] = useState(null);
    const [investFormData] = useInvestFormDataMutation();
    const { data, refetch, status } = usePlansFetchAllQuery();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [data])

    const OnChoosePlan = (planId, min, max) => {

        fundWallet.PlanId = planId;
        fundWallet.Min = min;
        fundWallet.Max = max;

        setFundWallet(fundWallet);
        setOpenModal(!isModalOpen)
    }

    const OnInvest = async () => {
        if (amount > fundWallet.Max) {
            alert(`The maximum amount allowed for the selected plan is ${common.Currency.format(fundWallet.Max)}`);
        }
        else if (amount < fundWallet.Min) {
            alert(`The minimum amount allowed for the selected plan is ${common.Currency.format(fundWallet.Min)}`);
        }
        else {
            setBusy(true);

            common.TaskDelay(common.DelayDuration).then(async () => {
                fundWallet.Amount = amount;
                setFundWallet(fundWallet);

                let response = await investFormData(fundWallet);
                console.log("_paymentDetail", JSON.stringify(response))
                if (response != null && response?.data?.statusCode === 200) {
                    setPaymentDetail(response?.data?.data);
                    setOpenModal(!isModalOpen)
                    setShowInvestmentWalletAddress(!showInvestmentWalletAddress);
                }
                else {
                    alert(response?.data?.message)
                }
                setBusy(false);
            })
        }
    }

    return (
        <DashboardLayout>
            <section className="about_us" style={{ marginTop: 80 }}>
                <div className="container">
                    <div className="row  align-content-center align-items-center justify-content-center">

                        <div className="col-md-12">
                            <h2 className="title_spectial" style={{ color: 'black' }}>Choose Plan</h2>
                        </div>

                        {
                            !showInvestmentWalletAddress ?

                                data?.map((plans, index) => {
                                    return (
                                        <div key={index} className="col-md-3 m-3 d-plan wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                            <div className="about-text">
                                                <h2 style={{ fontSize: '1.4rem' }}>{plans.planName}</h2>
                                                <ul style={{ listStyle: 'none' }}>
                                                    <li className="border-bottom-white mb-3"><i className="fa fa-check-circle mr-2"></i> Up to {plans.percentage}% for {plans.compounding}</li>
                                                    <li className="border-bottom-white mb-3"><i className="fa fa-check-circle mr-2"></i> Min deposit: {common.Currency.format(plans.minimum)}</li>
                                                    <li className="border-bottom-white mb-3">
                                                        <i className="fa fa-check-circle mr-2"></i> Max deposit: {common.Currency.format(plans.maximum)}
                                                    </li>
                                                </ul>
                                                <div className="text-center">
                                                    <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} className="home_btn" onClick={() => OnChoosePlan(plans.id, plans.minimum, plans.maximum)}>
                                                        <span className="btn_one wow">
                                                            <span onclick="document.getElementById('fundWallet').scrollIntoView({behavior:'smooth'})" data-wow-delay=".6s">Chose Plan</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="content-wrap content-wrap-80 bg-white">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between ">
                                        <h5 className="text-black-50">Investment Wallet Address</h5>
                                        <span className="text-danger" onClick={() => setShowInvestmentWalletAddress(!showInvestmentWalletAddress)} style={{ cursor: 'pointer' }}>Close</span>
                                    </div>
                                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between bg-light" Elevation="5">

                                        <div className="col-md-5">
                                            <div className="align-items-center justify-content-center">
                                                <img className="d-table" src={paymentDetail?.qrCode} alt="wallet address" style={{ width: '180px', height: '180px' }} />
                                                <em>Scan the qrcode</em>
                                            </div>
                                        </div>

                                        <div className="col-md-7 bg-light">
                                            <div className="d-table p-4 flex-wrap content-wrap">
                                                <h5>Transaction Id: <b className="ml-2">{paymentDetail?.transactionId}</b></h5>
                                                <hr />

                                                <h2 className="text-center m-5 text-success"><b>{paymentDetail?.amountInUSD}</b></h2>
                                                <em className="text-center">{paymentDetail?.message}</em>
                                                <hr />
                                                <b>BTC Address: <u className="text-danger ml-3">{paymentDetail?.address}</u></b>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                        }

                        {
                            !isModalOpen ? null :
                                <div className="modal" style={{ zIndex: 9999 }}>

                                    <div className="modal-content modal-content-40">
                                        <div className="modal-header d-flex flex-wrap align-items-center justify-content-between">
                                            <h4>Investment Model</h4>
                                            <b className="close text-danger" onClick={() => setOpenModal(!isModalOpen)}>&times;</b>
                                        </div>
                                        <hr />

                                        <TextField value={amount} onChange={(e) => { setAmount(e.target.value) }} sx={{ width: '100%', my: 3 }} id="standard-basic" label="Amount to Fund" variant="standard" />

                                        <div className="col-md-6">
                                            <h5 className="mb-1 d-table"> Fund Wallet </h5>
                                            <FormControlLabel control={<Switch checked={isBitcoinWallet} onChange={() => { setBitcoinWallet(!isBitcoinWallet); setOtherWallet(false); }} />} label="Bitcoin" />
                                            <FormControlLabel control={<Switch checked={isOtherWallet} onChange={() => { setOtherWallet(!isOtherWallet); setBitcoinWallet(false) }} />} label="Other Wallet" />
                                        </div>

                                        <LoadingButton
                                            color="primary"
                                            onClick={() => OnInvest()}
                                            loading={isBusy}
                                            disabled={isBitcoinWallet || isOtherWallet ? isBusy : true}
                                            loadingPosition="start"
                                            startIcon={<AttachMoneySharp />}
                                            variant="contained"
                                            sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                        >
                                            <span>Invest Now</span>
                                        </LoadingButton>
                                    </div>

                                </div>
                        }
                    </div>
                </div>
            </section>
        </DashboardLayout>
    )
}