        export let DasboardReportModel = {
            allBalance: 0,
            allActiveDeposit: 0,
            allDeposit: 0,
            users: 0,
            withdrawals: 0,
            plans: 0,
            activeInvestments: 0,
            completedInvestments: 0,
            pendingDeposits: 0,
            confirmedDeposits: 0,
            pendingWithdrawals: 0,
            completedWithdrawals: 0,
    }