
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as Icons from "react-icons/fa";

const CustomModal = forwardRef(({ children, height, width, title, isBusy }, ref) => {

    const style = {
        position: 'relative',
        display: "table",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight:'80vh',
        minWidth:'350px',
        height: height,
        overflow:'scroll',
        borderRadius: "10px",
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 0,
        pb:2,
    };

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleModal() { setOpen(!open); }
    }));

    return (
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                
                <div className='d-flex flex-wrap align-items-center justify-content-between mb-2 pb-0 pt-2 p-2'>
                    <h4 style={{paddingLeft:'20px', marginTop:'10px'}}>{title}</h4>
                    <button disabled={isBusy} onClick={() => setOpen(!open)} style={{color:'red', cursor:'pointer', fontSize:18, marginRight:'20px', border:'none', backgroundColor:'white'}}><Icons.FaWindowClose size={25} /></button>
                </div>

                <section className='border-top p-4' style={{maxHeight:'80vh', overflowY:'scroll', overflowX:'hidden', padding:0, paddingTop:10, margin:0}}>{children}</section>
            </Box>
        </Modal>
    );
});

export default CustomModal;