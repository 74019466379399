import { apiSlice } from './Config/BasicSetup'

export const dashboardDataReportApi = apiSlice.injectEndpoints({
    reducerPath: "dashboardDataReportApi",
    tagTypes: ['Plans'],
    endpoints: (build) => ({

        getUncompoundedInvestment: build.query({
            query: () => `invests/get-uncompounded-investment`,
            invalidatesTags: ['Plans']
        }),

        getUserDashboardDataReport: build.query({
            query: () => `DashboardDataReport/get-user-data-report`,
            invalidatesTags: ['Plans']
        }),

        getDashboardDataReport: build.query({
            query: () => `DashboardDataReport/data-report`,
            invalidatesTags: ['Plans']
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetUncompoundedInvestmentQuery,
    useGetUserDashboardDataReportQuery,
    useGetDashboardDataReportQuery,
} = dashboardDataReportApi;