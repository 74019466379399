import MainLayout from "../Shared/Mainlayout";

export default function About() {
    return (
        <MainLayout>
            <section id="about" className="about-section pt-150 pb-20">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-img mb-30">
                                <img src="/assets/img/about-img.svg" alt="image" className="wow fadeInLeft" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-content mb-30">
                                <div className="section-title mb-40">
                                    <h2 className="wow fadeInUp" data-wow-delay=".2s">About Us</h2>
                                </div>
                                <p className="mb-15 wow fadeInUp" data-wow-delay=".4s">
                                    Kapitol Investments is a property of Kapitol Investments Financial PTY Limited, a company duly registered. Kapitol Investments provides financial services under a Cooperative License. Both Kapitol Investments Financial PTY Limited are legal entities in Australia. Our investment professionals invest savers funds in financial instruments and manage the investments to ensure optimum return. To ensure security of savers funds, a company registered with the Security and Exchange Commission (SEC) on behalf of savers. These assets are marked to market periodically to ensure savers are protected.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}