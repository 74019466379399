import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'redux-persist'
import * as common from "../../../Utils/Common"

export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: common.BaseUrl,
        // credentials: "include",
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*');
            headers.set('auth-key', common.AuthKey);
            headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
            return headers
          },
    }),
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === REHYDRATE) {
            return action.payload[reducerPath]
        }
    },

    endpoints: (builder) => ({}),
});