import { useState, useEffect } from "react";
import DashboardLayout from "../Shared/DashboardLayout";
import * as common from "../Utils/Common";
import * as model from "../Models/Index";

const Referral = () => {
    const [values, setValues] = useState(model.currentUser.CurrentUserModel);

    useEffect(() => {
        let response = sessionStorage.getItem('user');
        if (response !== null) {
            setValues(JSON.parse(response));
        }

    }, [])

    const refUrl = () => {
        return `${window.location.host}/account/signup?sponsor=${values.userName}`;
    }

    const [referral, setReferral] = useState(model.referrals.ReferralsModel);

    return (
        <DashboardLayout>
            <div className="page-content content-wrap content-wrap-80 mt-5">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="page-title">Referrals</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="box p-3 bg-success alert alert-success text-white  shadow">
                                <div className="text-center">
                                    <h1 className="countnm font-size-50 m-0">{common.Currency.format(referral.TotalReferrals)}</h1>
                                    <div className="text-uppercase">Total Referral(s)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box p-3 bg-secondary alert alert-primary text-white  shadow">
                                <div className="text-center">
                                    <h1 className="countnm font-size-50 m-0">{common.Currency.format(referral.ReferralBonus)}</h1>
                                    <div className="text-uppercase">Referral Bonus</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box p-3 bg-primary text-white alert alert-danger shadow ">
                                <div className="text-center">
                                    <h1 className="countnm font-size-50 m-0">{common.Currency.format(referral.Balance)}</h1>
                                    <div className="text-uppercase">Referral Balance</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="m-5">Referral Link: <input value={refUrl()} style={{ padding: '10px', border: 'none', color: 'blue', width: '100%' }} /></h6>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Referral;