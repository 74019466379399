export default function Footer() {
    return (
        <>
            <footer className="footer pt-100 img-bg"
                style={{ backgroundImage: 'url(/assets/img/xcommon-bg.jpg.pagespeed.ic.EiIEvvAgRS.jpg)' }}>
                <div className='container'>
                    <div className='row'>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="footer-widget mb-60 wow fadeInLeft" data-wow-delay='.2s'>
                                <a href='index-2.html' className="logo mb-10"><img style={{ borderRadius: 100 }} src="/kapitol-investment-logo.png" width={80} height={80} alt="" /></a>
                                <p className='mb-30'>Kapitol Investments provides financial services under a Cooperative License. Both Kapitol Investments Financial PTY Limited are legal entities in Australia.</p>
                                <div className='footer-social-links'>
                                    <ul>
                                        <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li>
                                        <li><a href="#"><i className="lni lni-linkedin-original"></i></a></li>
                                        <li><a href="#"><i className="lni lni-instagram-original"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-widget mb-60 wow fadeInUp" data-wow-delay='.4s'>
                                <h4>Links</h4>
                                <ul className='footer-links'>
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#about">About</a>
                                    </li>
                                    <li>
                                        <a href="#features">Features</a>
                                    </li>
                                    <li>
                                        <a href="#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-6">
                            <div className="footer-widget mb-60 wow fadeInRight" data-wow-delay='.8s'>
                                <h4>Contact</h4>
                                <ul>
                                    <li className="mb-3">
                                        <p>+61 2 7908 3844</p>
                                    </li>
                                    <li className="mb-3">
                                        <p>info@kapitol-investments.com</p>
                                    </li>
                                    <li>
                                        <p>Address: 2 THE ESPLANADE PERTH, WESTERN AUSTRALIA, <br /> 6000 Australia</p>
                                    </li>
                                </ul>
                            </div>
                        </div >
                    </div >
                    <div className='copyright-area'>
                        <p className="mb-0 text-white text-center">Copyright &copy;{new Date().getFullYear()} | Kapitol Investments</p>
                    </div>
                </div >


                <a href="#" className="scroll-top" style={{marginRight: '110px', backgroundColor: 'red'}}>
                    <i className="lni lni-chevron-up"></i>
                </a>
            </footer >
        </>
    )
}