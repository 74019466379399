import { useState, useEffect } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import { useGetUserDashboardDataReportQuery } from "../Middlewares/Redux/dashboardDataReportApi";
import * as common from "../Utils/Common";
import * as model from "../Models/Index";

export default function UserDashboardDataReport() {

    const [reports, setReport] = useState(model.dasboardReport.DasboardReportModel);
    const { data, refetch, status } = useGetUserDashboardDataReportQuery();

    useEffect(() => {
        if (data !== null) {
            setReport(data);
        }
    }, [data])

    return (
        <>
            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Balance</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.allBalance > 0 ? reports?.allBalance : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Active Deposit</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.allActiveDeposit > 0 ? reports?.allActiveDeposit : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="dashboard-card">
                    <h3>All Deposit</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.allDeposit > 0 ? reports?.allDeposit : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>Deposits</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.Deposits > 0 ? reports?.Deposits : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>WITHDRAWALS</h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.withdrawals > 0 ? reports?.withdrawals : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        ACTIVE INVESTMENTS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.activeInvestments > 0 ? reports?.activeInvestments : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>COMPLETED INVESTMENTS</h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.completedInvestments > 0 ? reports?.completedInvestments : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '100%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        PENDING DEPOSITS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.pendingDeposits > 0 ? reports?.pendingDeposits : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        CONFIRMED DEPOSITS
                    </h3>

                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.confirmedDeposits > 0 ? reports?.confirmedDeposits : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        PENDING WITHDRAWALS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.pendingWithdrawals > 0 ? reports?.pendingWithdrawals : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div className="dashboard-card">
                    <h3>
                        COMPLETED WITHDRAWALS
                    </h3>
                    <div className="row dashboard-card-total">
                        <div className="col-md-5">
                            <h4>Total</h4>
                            <h4 className="text-success">{common.Currency.format(reports?.completedWithdrawals > 0 ? reports?.completedWithdrawals : 0)}</h4>
                            <span className="progress text-center" style={{width: '100%', height:'5px'}}>
                                <span className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></span>
                            </span>
                        </div>
                        <div className="col-md-7 text-right">
                            <Visibility />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}