import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as pages from "../Pages/Index"
import ProtectedRoutes from "./ProtectedRoute";

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<pages.Home />} />
        <Route exact path="about" element={<pages.About />} />
        <Route exact path="contact" element={<pages.Contact />} />
        <Route exact path="investment-plans" element={<pages.Plans />} />
        <Route exact path="account/login" element={<pages.Login />} />
        <Route exact path="account/signup" element={<pages.Signup />} />
        <Route element={<ProtectedRoutes />}>
          <Route exact path="dashboard" element={<pages.Dashboard />} />
          <Route exact path="dashboard/invest" element={<pages.Invest />} />
          <Route exact path="dashboard/withdrawal" element={<pages.Withdrawal />} />
          <Route exact path="dashboard/referral" element={<pages.Referral />} />
          <Route exact path="dashboard/basic-settings" element={<pages.BasicSettings />} />
          <Route exact path="dashboard/plan-settings" element={<pages.PlansSettings />} />
          <Route exact path="dashboard/coin-settings" element={<pages.CoinSettings />} />
          <Route exact path="dashboard/compounding" element={<pages.Compounding />} />
          <Route exact path="dashboard/manage-users" element={<pages.ManageUsers />} />
          <Route exact path="dashboard/manage-deposits" element={<pages.ManageDeposits />} />
          <Route exact path="dashboard/manage-withdrawal" element={<pages.ManageWithdrawal />} />
          <Route exact path="dashboard/manage-transaction-history" element={<pages.ManageTransactionHistory />} />
          <Route exact path="dashboard/compound-investment" element={<pages.CompoundInvestment />} />
          <Route exact path="dashboard/mailing-system" element={<pages.MailingSystem />} />
          <Route exact path="dashboard/user-profile" element={<pages.UserProfile />} />
          <Route exact path="dashboard/earning" element={<pages.Earning />} />
          <Route exact path="dashboard/deposit" element={<pages.Deposits />} />
        </Route>
        <Route exact path="*" element={<pages.PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}