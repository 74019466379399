import * as common from "../Utils/Common";
import { usePlansFetchAllQuery } from "../Middlewares/Redux/plansSettingApi"
import MainLayout from "../Shared/Mainlayout";

export default function Plans() {

    const { data, refetch, status } = usePlansFetchAllQuery();
    return (
        <MainLayout>
            <section id="plans" className="feature-section pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 mx-auto">
                            <div className="section-title text-center mb-55">
                                <h2 className="mb-20 wow fadeInUp" data-wow-delay=".2s">INVESTMENT PLAN</h2>
                            </div>
                        </div>
                    </div>
                    <div className="features-wrapper">
                        <div className="row justify-content-center">
                            {
                                data?.map((item, index) => {
                                    return (
                                        <div key={index} className="col-xl-4 col-lg-4 col-md-6 mb-3">
                                            <div className="single-feature text-center mb-30 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="feature-img mb-20">
                                                    <img width={70} src="/assets/img/feature-2.svg" alt="" />
                                                </div>
                                                <div className="feature-content">
                                                    <h5>{item.planName}</h5>
                                                    <ul style={{ listStyle: 'none', color: 'white' }}>
                                                        <li className="border-bottom-white mb-3"><i className="fa fa-check-circle mr-2"></i> Up to {item.percentage} % for {item.compounding}</li>
                                                        <li className="border-bottom-white mb-3"><i className="fa fa-check-circle mr-2"></i> Min deposit: {common.Currency.format(item.minimum)}</li>
                                                        <li className="border-bottom-white mb-3">
                                                            <i className="fa fa-check-circle mr-2"></i> Max deposit: {common.Currency.format(item.maximum)}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}