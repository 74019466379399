import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import CustomDialog from "../Utils/CustomDialog"
import CustomModal from "../Utils/CustomModal"
import CustomAlert from "../Utils/CustomAlert"
import DashboardLayout from '../Shared/DashboardLayout';
import * as model from "../Models/Index";
import { Divider, MenuItem, TextField, FormControlLabel, Button, Paper, Box, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Edit, Delete, SaveSharp } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as common from "../Utils/Common";
import { useGetUsersQuery, useDeleteUserMutation, useToggleUserMutation } from "../Middlewares/Redux/accountApi";
import { useGetSelectedUserWalletMutation, useUpdateWalletAddressMutation, useFundWalletMutation, useGetUserWalletQuery, useGetWalletQuery } from "../Middlewares/Redux/walletApi";
import { useGetSelectedUserWalletAddressMutation, useSaveWalletAddressMutation } from "../Middlewares/Redux/walletAddressApi";
import { handleSignup, handleAdminLogin } from "../Middlewares/Redux/authSlice";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ManageUsers() {
    const [records, setRecords] = useState([]);
    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);
    const [isUserWallet, setUserWallet] = useState(false);
    const [values, setValues] = useState(model.signup.SignupModel);
    const [wallet, setWallet] = useState(model.walletDetail.WalletDetailModel);
    const [walletAddress, setWalletAddress] = useState([]);
    const [wAddress, setWAddress] = useState(model.walletAddress.WalletAddressModel);
    const [manageWallet, setManageWallet] = useState(model.manageFunds.ManageFundsModel);
    const [selectedUser, setSelectedUser] = useState(null);

    const getUsers = useGetUsersQuery();
    const [deleteUser] = useDeleteUserMutation();
    const [toggleUser] = useToggleUserMutation();
    const [getSelectedUserWalletAddress] = useGetSelectedUserWalletAddressMutation();
    const [getSelectedUserWallet] = useGetSelectedUserWalletMutation();
    const [updateWalletAddress] = useUpdateWalletAddressMutation();
    const [saveWalletAddress] = useSaveWalletAddressMutation();
    const [fundWallet] = useFundWalletMutation();

    const dispatch = useDispatch();

    const alertRef = useRef(null);
    const dialogRef = useRef(null);
    const modalRef = useRef(null);
    const addWalletAddressRef = useRef(null);

    const OnOpenModal = () => {
        setValues(model.signup.SignupModel);
        modalRef.current.toggleModal();
    }

    const OnEditAccount = (item) => {
        setBusy(true);
        setValues(model.signup.SignupModel);
        setValues(item);
        modalRef.current.toggleModal();
        setBusy(false);
    }

    const OnDeleteRequest = (item, name) => {
        setBusy(true);
        let newMessage = (<><b className='mb-4'>{name}, will be deleted.</b> <br /> <em className='text-danger'>Are you sure?</em></>);
        setMessages(newMessage);
        setValues(item);
        setBusy(false);
        dialogRef.current.toggleDialog();
    }

    const OnDelete = async () => {
        setBusy(true);

        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await deleteUser(values.Id);
            setBusy(false);

            setMessages(response?.data?.message);
            dialogRef.current.toggleDialog();
            alertRef.current.toggleAlert();
        })
    }

    const OnToggle = (id) => {
        setBusy(true);
        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await toggleUser(id);
            setBusy(false);

            setMessages(response?.data?.message);
            alertRef.current.toggleAlert();
        });
    }

    const SaveData = () => {
        setBusy(true);

        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await dispatch(handleSignup(values)).unwrap();
            setBusy(false);

            if (response !== null) {
                setValues(model.signup.SignupModel);
            }
            setMessages(response?.data?.message);
            modalRef.current.toggleModal();
            alertRef.current.toggleAlert();
        })
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const onPostLogin = async (userId) => {
        try {
            setBusy(true);
            common.TaskDelay(common.DelayDuration).then(async () => {
                let response = await dispatch(handleAdminLogin(userId)).unwrap();
                setBusy(false);
                if (response !== null) {
                    return <Navigate replace to="/dashboard" />
                } else {
                    setMessages(response?.data?.message);
                }
            })
        } catch (error) {
            setBusy(false);
            alert(error.message);
        }
    }

    let count = 1;

    const OnOpenAddWalletAddress = () => {
        setBusy(false);
        setWAddress(model.walletAddress.WalletAddressModel);
        addWalletAddressRef.current.toggleModal()
    }

    const getWalletAddress = async () => {
        let walletAddress = await getSelectedUserWalletAddress(selectedUser);
        setWalletAddress(walletAddress.data);
    }

    const OnViewWallet = async (item, userId) => {

        setSelectedUser(userId);

        setValues(model.signup.SignupModel);
        setValues(item);

        let wallet = await getSelectedUserWallet(userId);
        setWallet(wallet.data);

        await getWalletAddress();

        setUserWallet(!isUserWallet)
    }

    const OnUpdateWalletAddress = async (value, walletId) => {
        await updateWalletAddress(value, walletId);
        await getWalletAddress();
    }

    const SaveWalletAddress = async () => {
        setBusy(true)
        setTimeout(async () => {
            wAddress.UserId = selectedUser;
            let response = await saveWalletAddress(wAddress);
            setBusy(false)

            await getWalletAddress();

            addWalletAddressRef.current.toggleModal();
            setWAddress(model.walletAddress.WalletAddressModel);
            setMessages(response?.data?.message);
            alertRef.current.toggleAlert();
        }, 2000);
    }

    const OnFundWallet = async () => {
        setBusy(true)
        try {
            setTimeout(async () => {
                manageWallet.UserId = selectedUser;
                manageWallet.UserName = selectedUser;
                let response = await fundWallet(manageWallet);

                if (response != null && response.statusCode === 200) {
                    setManageWallet(model.manageFunds.ManageFundsModel);
                }

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
                setUserWallet(!isUserWallet);
                setBusy(false)
            }, 2000);
        } catch (error) {
            setBusy(false);
            setMessages(error.message);
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <DashboardLayout>
            <div className='container mb-5' style={{ marginTop: '50px' }}>
                <div className='row mb-5'>
                    <div className='col-md-6'><h2>Manage Users</h2></div>
                    {
                        !isUserWallet ?
                            <div className='col-md-6 text-right'><button disabled={isBusy} className='btn btn-success' onClick={() => OnOpenModal()}>New</button></div>
                            :
                            <div className='col-md-6 text-right'><button disabled={isBusy} className='btn btn-danger' onClick={() => setUserWallet(!isUserWallet)}>Close</button></div>
                    }
                </div>

                {
                    isUserWallet ?

                        <div className="card content-wrap content-wrap-90">
                            <div className="card-body">
                                <div className="">
                                    <section className="content">
                                        <div className="row">

                                            <div className="col-xl-4 col-lg-5">

                                                <div className="box card card-body">
                                                    <div className="box-body box-profile">
                                                        <img className="rounded img-fluid mx-auto d-block max-w-150 bg-white" alt="avatar" src="/avatar.png" />

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="bg-danger p-3 mt-3 text-white">
                                                                    <h4 className="media media-single p-1 text-white">
                                                                        Wallet Balance: {wallet !== null ? common.Currency.format(wallet?.AvailableBalance) : common.Currency.format(0)}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-8 col-lg-7">
                                                <div className="box box-solid box-inverse box-dark mb-4">
                                                    <div className="box-header with-border mb-4">
                                                        <h3 className="box-title">Personal details</h3>
                                                    </div>
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <input type="hidden" name="_token" value="t8ZLbg1lpn1hVMeftywn9Q9wC7I8oWzBulHQt3ZT" />                                                            <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">Full Name</label>
                                                                    <div className="col-sm-8">
                                                                        <input id="first_name" name="full_name" value={values.Name} type="text" className="form-control" />

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">UserName</label>
                                                                    <div className="col-sm-8">
                                                                        <input id="last_name" name="username" value={values.UserName} type="text" className="form-control" />

                                                                    </div>
                                                                </div>


                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">Email Adress</label>
                                                                    <div className="col-sm-8">
                                                                        <input id="email" name="email" value={values.Email} type="email" className="form-control" />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">User Role</label>
                                                                    <div className="col-sm-8">
                                                                        <input value={values.UserRole} type="text" className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="box box-solid box-inverse box-dark mt-4">
                                                    <div className="box-header with-border mb-4">
                                                        <h3 className="box-title">My Wallet</h3>
                                                    </div>
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {
                                                                    walletAddress?.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className="form-group row">
                                                                                <label className="col-sm-3 col-form-label active">{item.coin}</label>
                                                                                <div className="col-sm-9">
                                                                                    <input className="form-control" onChange={(e) => OnUpdateWalletAddress(e.target.value, item.id)} value={item.address} type="text" placeholder="Wallet address" />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                                <div className="form-group row">
                                                                    <label className="col-sm-2 col-form-label"></label>
                                                                    <div className="col-sm-10">

                                                                        <button type="button" className=" btn btn-warning text-white waves-effect waves-light" onClick={() => OnOpenAddWalletAddress()}>
                                                                            Add New Wallet
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="box box-solid box-inverse box-dark mt-4">
                                                    <div className="box-header with-border">
                                                        <h3 className="box-title">Manage Funds</h3>
                                                    </div>
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-12">

                                                                <div className="form-group row">

                                                                    <label className="col-sm-4 col-form-label active">Select Wallet:</label>
                                                                    <div className="col-sm-8">
                                                                        <select value={manageWallet.WalletType} onChange={(e) => setManageWallet({ ...manageWallet, WalletType: e.target.value })} className="form-control select2 w-p100 select2-hidden-accessible" aria-hidden="true">
                                                                            <option value="" selected="" disabled="">Choose Option</option>
                                                                            <option value="Bitcoin">Bitcoin</option>
                                                                            <option value="Other Wallet">Other Wallet</option>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">Select Fund Type:</label>
                                                                    <div className="col-sm-8">
                                                                        <select value={manageWallet.FundType} onChange={(e) => setManageWallet({ ...manageWallet, FundType: e.target.value })} className="form-control select2 w-p100 select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                            <option value="" selected="" disabled="">Choose Option</option>
                                                                            <option value="Add">Add</option>
                                                                            <option value="Substract">Substract</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">Send fund to:</label>
                                                                    <div className="col-sm-8">
                                                                        <select value={manageWallet.SendFundTo} onChange={(e) => setManageWallet({ ...manageWallet, SendFundTo: e.target.value })} className="form-control select2 w-p100 select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                            <option value="" selected="" disabled="">Choose Option</option>
                                                                            <option value="Balance">Balance</option>
                                                                            <option value="Profits">Profits</option>
                                                                            <option value="ReferralBonus">Referral Bonus</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">Amount</label>
                                                                    <div className="col-sm-8">
                                                                        <input value={manageWallet.Amount} onChange={(e) => setManageWallet({ ...manageWallet, Amount: e.target.value })} className="form-control" type="number" placeholder="Enter Amount" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active"></label>
                                                                    <div className="col-sm-8">
                                                                        <button disabled={isBusy} onClick={() => OnFundWallet()} type="submit" className="btn btn-warning text-white waves-effect waves-light">
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </div>
                        </div>
                        :
                        <Paper sx={{ width: '100%', overflow: 'auto' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S/N</TableCell>
                                            <TableCell style={{ minWidth: 200 }}>Name</TableCell>
                                            <TableCell style={{ minWidth: 200 }}>User Name</TableCell>
                                            <TableCell style={{ minWidth: 250 }}>Email</TableCell>
                                            <TableCell style={{ minWidth: 100 }}>User Role</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell style={{ minWidth: 250 }}>Action</TableCell>
                                            <TableCell>Password</TableCell>
                                            <TableCell>Login</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {getUsers?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    // <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    //     {columns.map((column) => {
                                                    //         const value = row[column.id];
                                                    //         return (
                                                    //             <TableCell key={column.id} align={column.align}>
                                                    //                 {column.format && typeof value === 'number'
                                                    //                     ? column.format(value)
                                                    //                     : value}
                                                    //             </TableCell>
                                                    //         );
                                                    //     })}
                                                    // </TableRow>

                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                        <TableCell component="th" scope="row"> {count++}</TableCell>
                                                        <TableCell component="th" scope="row"> {row.Name}</TableCell>
                                                        <TableCell component="th" scope="row"> {row.UserName}</TableCell>
                                                        <TableCell component="th" scope="row"> {row.Email}</TableCell>
                                                        <TableCell component="th" scope="row"> {row.UserRole}</TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel control={<Switch checked={row.IsActive} onChange={() => OnToggle(row.Id)} />} label={row.IsActive ? "Active" : "Disabled"} />
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Button disabled={isBusy} color="error" onClick={() => OnEditAccount(row)}><Edit disabled={isBusy} style={{ cursor: 'pointer' }} color="primary" /></Button>
                                                            <Button disabled={isBusy} color="error" onClick={() => OnDeleteRequest(row.Id, row.name)}><Delete disabled={isBusy} style={{ cursor: 'pointer', }} color="error" /></Button>
                                                            <Button disabled={isBusy} color="inherit" onClick={() => OnViewWallet(row, row.Id)}><Visibility disabled={isBusy} style={{ cursor: 'pointer', }} color="dark" /></Button>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Button color='primary'>Change</Button>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Button color='success' onClick={() => onPostLogin(row.Id)}>Login</Button>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Button color='success'>{row.date}</Button>
                                                        </TableCell>

                                                    </TableRow>

                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={getUsers?.data?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                }

                <CustomModal ref={modalRef} width={'600px'} height={'200px'}>
                    <div className='d-flex flex-wrap align-items-center justify-content-between mb-2'>
                        <h4>Manage User</h4>
                        <button onClick={() => modalRef.current.toggleModal()} className='btn btn-close'>Close</button>
                    </div>
                    <Divider />

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                        <TextField value={values.Name} onChange={(e) => { setValues({ ...values, Name: e.target.value }) }} label="Full Name" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values.Email} onChange={(e) => { setValues({ ...values, Email: e.target.value }) }} label="Email" type="email" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values.BitcoinWalletAddress} onChange={(e) => { setValues({ ...values, BitcoinWalletAddress: e.target.value }) }} label="Bitcoin Wallet Address" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values.SecreteQuestion} onChange={(e) => { setValues({ ...values, SecreteQuestion: e.target.value }) }} label="Secrete Question" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values.SecreteQuestionAnswer} onChange={(e) => { setValues({ ...values, SecreteQuestionAnswer: e.target.value }) }} label="Secrete Question Answer" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                        <TextField value={values.Username} onChange={(e) => { setValues({ ...values, Username: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Username" variant="standard" />

                        <FormControl sx={{ width: '100%', mt: 2 }} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => { setValues({ ...values, Password: e.target.value }) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        <div className='text-right' style={{ float: 'right', width: '100%' }}>
                            <LoadingButton
                                color="primary"
                                onClick={() => SaveData()}
                                loading={isBusy}
                                loadingPosition="start"
                                startIcon={<SaveSharp />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                            >
                                <span>Submit</span>
                            </LoadingButton>
                        </div>

                    </div>

                </CustomModal>

                <CustomModal ref={addWalletAddressRef} width={'600px'} height={'200px'}>
                    <div className='d-flex flex-wrap align-items-center justify-content-between mb-2'>
                        <h4>Add Wallet Address</h4>
                        <button onClick={() => addWalletAddressRef.current.toggleModal()} className='btn btn-close'>Close</button>
                    </div>
                    <Divider />

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">


                        <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                            <TextField sx={{ width: '100%' }} id="WalletType" label="Wallet Type" value={wAddress.Coin} onChange={(e) => setWAddress({ ...wAddress, Coin: e.target.value })} select>
                                <MenuItem value="Bitcoin">Bitcoin</MenuItem>
                                <MenuItem value="Other Wallet">Other Wallet</MenuItem>
                            </TextField>
                        </div>

                        <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                            <TextField value={wAddress.Address} onChange={(e) => setWAddress({ ...wAddress, Address: e.target.value })} label="Address" sx={{ width: '100%', my: 1 }} id="standard-basic" />
                        </div>

                        <div className='text-right' style={{ float: 'right', width: '100%' }}>
                            <LoadingButton
                                color="primary"
                                onClick={() => SaveWalletAddress()}
                                loading={isBusy}
                                disabled={isBusy}
                                loadingPosition="start"
                                startIcon={<SaveSharp />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </div>
                    </div>

                </CustomModal>

                <CustomDialog ref={dialogRef} >
                    <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                    <div className='row'>
                        <div className='col-md-6'><button className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                        <div className='col-md-6 text-right'>
                            <LoadingButton
                                color="error"
                                onClick={() => OnDelete()}
                                loading={isBusy}
                                disabled={isBusy}
                                loadingPosition="start"
                                startIcon={<Delete />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 2, py: 1 }}
                            >
                                <span>Yes</span>
                            </LoadingButton>
                        </div>
                    </div>
                </CustomDialog>
                <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

            </div >
        </DashboardLayout >
    );
}

/*
<div className="box box-solid box-inverse box-dark mt-4">
                                                    <div className="box-header with-border">
                                                        <h3 className="box-title">Manage Funds</h3>
                                                    </div>
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-12">

                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label active">Select Wallet:</label>

                                                                    <div className="col-sm-8">
                                                                        <select className="form-control select2 w-p100 select2-hidden-accessible" aria-hidden="true">
                                                                            <option value="" selected="" disabled="">Choose Option</option>
                                                                            <option value="Bitcoin">Bitcoin</option>
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-sm-4 col-form-label active">Select Fund Type:</label>
                                                                        <div className="col-sm-8">
                                                                            <select className="form-control select2 w-p100 select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                                <option value="" selected="" disabled="">Choose Option</option>
                                                                                <option value="Add">Add</option>
                                                                                <option value="Add">Substract</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label active">Send fund to:</label>
                                                                            <div className="col-sm-8">
                                                                                <select className="form-control select2 w-p100 select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                                    <option value="" selected="" disabled="">Choose Option</option>
                                                                                    <option value="Balance">Balance</option>
                                                                                    <option value="Profits">Profits</option>
                                                                                    <option value="ReferralBonus">Referral Bonus</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <label className="col-sm-4 col-form-label active">Amount</label>
                                                                                <div className="col-sm-8">
                                                                                    <input className="form-control" type="number" placeholder="Enter Amount" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group row">
                                                                                <label className="col-sm-4 col-form-label active"></label>
                                                                                <div className="col-sm-8">
                                                                                    <button type="submit" disabled="@FormControls.IsBusy" className="btn btn-warning text-white waves-effect waves-light">
                                                                                        @if (FormControls.IsBusy){
                                                                                            <span>Please wait</span>
                                                                                        }else{
                                                                                            <span>Submit</span>
                                                                                        }
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
*/