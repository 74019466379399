import React, {forwardRef, useImperativeHandle, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

 const CustomAlert = forwardRef(({children}, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleAlert(){ setOpen(!open); }
  }));

  return (
      <Dialog
      
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className='row pb-3 mb-1' style={{borderBottom:"1px solid silver"}}>
              <div className='col-md-6'><h3>Info</h3></div>
              <div className='col-md-6 text-right'><button onClick={() => setOpen(false)} className='btn btn-outline-danger'>X</button></div>
          </div>
        </DialogTitle>
        <DialogContent style={{width: "400px", minHeight:"80px", paddingBottom:"8px"}}>
          <DialogContentText id="alert-dialog-description">
          {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
});

export default CustomAlert;