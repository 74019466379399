import { apiSlice } from './Config/BasicSetup'

export const mailingSystemApi = apiSlice.injectEndpoints({
    reducerPath: "mailingSystemApi",
    tagTypes: ['MailingSystem'],
    endpoints: (build) => ({

        maillingSystemSendMail: build.mutation({
            query: (data) => ({
                url: `maillingSystem/send-mail`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['MailingSystem']
        }),
    }),
    overrideExisting: true,
})

export const {
    useMaillingSystemSendMailMutation,
} = mailingSystemApi;