import MainLayout from "../Shared/Mainlayout";

const PageNotFound = () => {
    return (
        <MainLayout>
            <section id="about" className="about-section pt-130 pb-20">
                <div className="container">
                    <h1>404</h1>
                    <h3>Opps! This page Could Not Be Found!</h3>
                    <p>Sorry bit the page you are looking for does not exist, have been removed or name changed</p>
                </div>
            </section>
        </MainLayout>
    )
};

export default PageNotFound;
