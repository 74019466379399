import { apiSlice } from './Config/BasicSetup'

export const investApi = apiSlice.injectEndpoints({
    reducerPath: "investApi",
    tagTypes: ['Invest'],
    endpoints: (build) => ({

        getUncompoundedInvestment: build.query({
            query: () => `invests/get-uncompounded-investment`,
            invalidatesTags: ['Invest']
        }),

        getInvestments: build.query({
            query: () => `invests/get-all`,
            invalidatesTags: ['Invest']
        }),

        getUserInvestments: build.query({
            query: () => `invests/get-user-investment`,
            invalidatesTags: ['Invest']
        }),

        investFormData: build.mutation({
            query: (data) => ({
                url: `invests/invest`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['Invest']
        }),

        reInvestment: build.mutation({
            query: (id) => ({
                url: `invests/re-invest?investmentId=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Invest']
        }),

        setCompounding: build.mutation({
            query: (id) => ({
                url: `invests/compounding?investmentId=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Invest']
        }),

        confirmInvestment: build.mutation({
            query: (id, userId) => ({
                url: `invests/confirm-investment?id=${id}&userId=${userId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Invest']
        }),

    }),
    overrideExisting: true,
})

export const {
    useGetUncompoundedInvestmentQuery,
    useGetInvestmentsQuery,
    useGetUserInvestmentsQuery,
    useInvestFormDataMutation,
    useReInvestmentMutation,
    useSetCompoundingMutation,
    useConfirmInvestmentMutation,
} = investApi;