import Paper from '@mui/material/Paper';
import DashboardLayout from "../Shared/DashboardLayout";

const Earning = () => {

    return (
        <DashboardLayout>
            <div className="content-wrap content-wrap-90" style={{ marginTop: 40 }}>
                <h5 className="text-black-50">Earning</h5>

                <Paper elevation={3} sx={{ mt: 1, p: 5 }}>

                    <h3>Coming soon</h3>

                </Paper>

            </div>

        </DashboardLayout>
    )
}

export default Earning;