import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as common from "../../Utils/Common"

const initialState = {
    user: null,
    token: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed
    error: null,
}

export const handleLogin = createAsyncThunk('account/login', async (data) => {
    try {
        const response = await axios.post(common.BaseUrl + 'account/login', data, {
            headers: {
                'auth-key': common.AuthKey,
            },
        })
        return response.data;
    } catch (error) {
        return error.message;
    }
})

export const handleAdminLogin = createAsyncThunk('account/admin-login', async (userId) => {
    try {
        const response = await axios.post(common.BaseUrl + `account/admin-user-login?userId=${userId}`, null, {
            headers: {
                'auth-key': common.AuthKey,
            },
        })
        return response.data;
    } catch (error) {
        return error.message;
    }
})

export const handleSignup = createAsyncThunk('account/signup', async (data) => {
    try {
        const response = await axios.post(common.BaseUrl + 'account/signup', data, {
            headers: {
                'auth-key': common.AuthKey,
            },
        })
        return response.data;
    } catch (error) {
        return error.message;
    }
})

export const handleLogout = createAsyncThunk('account/logout', async () => {
    try {
        const response = await axios.post(common.BaseUrl + 'account/logout', true);
        return response.data;
    } catch (error) {
        return error.message;
    }
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder

            //auth user
            .addCase(handleLogin.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(handleLogin.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload?.data;
                state.token = action.payload?.data?.token;

                global.user = action.payload?.data;
                global.userClaims = action.payload?.data?.claims;
                global.userEmail = action.payload?.data?.email;
                global.userFullName = action.payload?.data?.fullName;
                global.userId = action.payload?.data?.id;
                global.userIsAuthenticated = action.payload?.data?.isAuthenticated;
                global.userRole = action.payload?.data?.role;
                global.userToken = action.payload?.data?.token;
                global.userName = action.payload?.data?.userName;
            })
            .addCase(handleLogin.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

            
            //auth user
            .addCase(handleAdminLogin.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(handleAdminLogin.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload?.data;
                state.token = action.payload?.data?.token;

                global.user = action.payload?.data;
                global.userClaims = action.payload?.data?.claims;
                global.userEmail = action.payload?.data?.email;
                global.userFullName = action.payload?.data?.fullName;
                global.userId = action.payload?.data?.id;
                global.userIsAuthenticated = action.payload?.data?.isAuthenticated;
                global.userRole = action.payload?.data?.role;
                global.userToken = action.payload?.data?.token;
                global.userName = action.payload?.data?.userName;
            })
            .addCase(handleAdminLogin.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })


            //signup
            .addCase(handleSignup.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(handleSignup.fulfilled, (state, action) => {
                state.status = "succeeded";

                state.user = action.payload?.data;
                state.token = action.payload?.data?.token;

                global.user = action.payload?.data;
                global.userClaims = action.payload?.data?.claims;
                global.userEmail = action.payload?.data?.email;
                global.userFullName = action.payload?.data?.fullName;
                global.userId = action.payload?.data?.id;
                global.userIsAuthenticated = action.payload?.data?.isAuthenticated;
                global.userRole = action.payload?.data?.role;
                global.userToken = action.payload?.data?.token;
                global.userName = action.payload?.data?.userName;

                global.setUser = action.payload?.data?.userName ? localStorage.setItem("user", JSON.stringify(action.payload?.data)) : null;
                global.setToken = action.payload?.data?.userName ? localStorage.setItem("token", action.payload?.data?.token) : null;
                global.setUsername = action.payload?.data?.userName ? localStorage.setItem("userName", action.payload?.data?.userName) : null;
            })
            .addCase(handleSignup.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })

            //logout
            .addCase(handleLogout.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(handleLogout.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = null;
                state.token = null;

                global.user = null;
                global.userName = null;
                global.token = null;
            })
            .addCase(handleLogout.rejected, (state, action) => {
                state.status = "failed";

                state.user = null;
                state.token = null;
            })
    }
});

export const getAuthUser = (state) => state.auth.user;
export const getAuthToken = (state) => state.auth.token;
export const getAuthStatus = (state) => state.auth.status;
export const getAuthError = (state) => state.auth.error;

export default authSlice.reducer;