import { apiSlice } from './Config/BasicSetup'

export const withdrawalApi = apiSlice.injectEndpoints({
    reducerPath: "walletApi",
    tagTypes: ['Withdrawal'],
    endpoints: (build) => ({

        getWithdrawalRequest: build.query({
            query: () => `withdrawal/get-all`,
            invalidatesTags: ['Withdrawal']
        }), 

        getUserWithdrawals: build.query({
            query: (userId) => `withdrawal/get-user-withrawal-request?userId=${userId}`,
            invalidatesTags: ['Withdrawal']
        }),

        getUserWithdrawalRequest: build.query({
            query: () => `withdrawal/get-user-withdrawal-request`,
            invalidatesTags: ['Withdrawal']
        }),

        withdrawal: build.mutation({
            query: (data) => ({
                url: `withdrawal/withdraw`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['Withdrawal']
        }),

        confirmWithdrawalRequest: build.mutation({
            query: (id) => ({
                url: `withdrawal/confirm-request?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Withdrawal']
        }),

        deleteWithdrawalRequest: build.mutation({
            query: (id) => ({
                url: `withdrawal/delete?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Withdrawal']
        })
    }),
    overrideExisting: true,
})
  
export const {
    useGetWithdrawalRequestQuery,
    useGetUserWithdrawalsQuery,
    useGetUserWithdrawalRequestQuery,
    useWithdrawalMutation,
    useConfirmWithdrawalRequestMutation,
    useDeleteWithdrawalRequestMutation
} = withdrawalApi;