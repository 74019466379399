import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomDialog from "../Utils/CustomDialog"
import * as common from "../Utils/Common";
import DashboardLayout from '../Shared/DashboardLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import { Calculate, InsertChart } from "@mui/icons-material";
import Moment from 'react-moment';
import CustomAlert from '../Utils/CustomAlert';
import { useGetUncompoundedInvestmentQuery, useReInvestmentMutation, useSetCompoundingMutation } from "../Middlewares/Redux/investApi";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CompoundInvestment() {
    const [records, setRecords] = useState([]);
    const { data, refetch, status } = useGetUncompoundedInvestmentQuery();
    const [reInvestment] = useReInvestmentMutation();
    const [setCompounding] = useSetCompoundingMutation();

    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);

    const alertRef = useRef(null);
    const dialogRef = useRef(null);

    let count = 1;

    const OnCompoundInvestment = (id) => {
        setBusy(true);

        try {
            common.TaskDelay(common.DelayDuration).then(async () => {
                let response = await setCompounding(id);
                setBusy(false);

                if (response !== null) {
                    refetch()
                }

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            })
        } catch (error) {
            setMessages(error.message);
            alertRef.current.toggleAlert();
        }
    }

    const OnReInvest = (id) => {
        setBusy(true);

        try {

            common.TaskDelay(common.DelayDuration).then(async () => {
                let response = await reInvestment(id);
                setBusy(false);

                if (response !== null) {
                    refetch();
                }

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            })
        } catch (error) {
            setMessages(error.message);
            alertRef.current.toggleAlert();
        }
    }

    return (
        <DashboardLayout>
            <div className='container' style={{ marginTop: '80px' }}>
                <div className='row mb-5'>
                    <div className='col-md-6'><h2>Compound Investment</h2></div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S/N</StyledTableCell>
                                <StyledTableCell>User Name</StyledTableCell>
                                <StyledTableCell>Plan</StyledTableCell>
                                <StyledTableCell>Amount</StyledTableCell>
                                <StyledTableCell>Profits</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row"> {count++}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.UserId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.PlanId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> <b className="text-success">{common.Currency.format(row.AmountToInvest)}</b></StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> <b className='text-success'>{common.Currency.format(row.Profits)}</b></StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <Moment fromNow>{row.ActionDate}</Moment>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {
                                            !row.IsCompounded ?
                                                <LoadingButton
                                                    color="primary"
                                                    onClick={() => OnCompoundInvestment(row.Id)}
                                                    // loading={isBusy}
                                                    disabled={isBusy}
                                                    loadingPosition="start"
                                                    startIcon={<Calculate />}
                                                    variant="contained"
                                                    sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                                >
                                                    <span>Set</span>
                                                </LoadingButton>
                                                :
                                                <LoadingButton
                                                    color="success"
                                                    onClick={() => OnReInvest(row.Id)}
                                                    // loading={isBusy}
                                                    disabled={isBusy}
                                                    loadingPosition="start"
                                                    startIcon={<InsertChart />}
                                                    variant="contained"
                                                    sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                                >
                                                    <span>Reinvest</span>
                                                </LoadingButton>
                                        }

                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>


                <CustomDialog ref={dialogRef} >
                    <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                    <div className='row'>
                        <div className='col-md-6'><button className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                        {/* <div className='col-md-6 text-right'><button className='btn btn-danger' onClick={OnDelete}>Yes</button></div> */}
                    </div>
                </CustomDialog>

                <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

            </div >
        </DashboardLayout >
    );
}