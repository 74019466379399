import { useLayoutEffect, useState, useRef } from "react"
import { TextField, Paper } from '@mui/material';
import DashboardLayout from "../Shared/DashboardLayout";
import * as common from "../Utils/Common";
import * as model from "../Models/Index";
import LoadingButton from '@mui/lab/LoadingButton';
import { SaveAlt } from "@mui/icons-material";
import CustomAlert from '../Utils/CustomAlert';
import { useBasicSettingsFormDataMutation, useGetBasicSettingsQuery } from "../Middlewares/Redux/basicSettingsApi"

const BasicSettings = () => {

    const [values, setValues] = useState(model.basicSettings.BasicSettingsModel);
    const [isBusy, setBusy] = useState(false);
    const { data, refetch } = useGetBasicSettingsQuery();
    const [basicSettingsFormData] = useBasicSettingsFormDataMutation();

    const alertRef = useRef(null);
    const [messages, setMessages] = useState();

    const SaveData = () => {
        try {
            setBusy(true);

            common.TaskDelay(common.DelayDuration).then(async () => {
                const response = await basicSettingsFormData(values);
                console.info("response: ", response)
                setBusy(false);
                refetch();
                setMessages(response?.data?.message)
                alertRef.current.toggleAlert()
            })
        } catch (error) {
            setMessages("\nAn error has occured, please check your internet connection and try again")
            alertRef.current.toggleAlert()
        }
    }

    useLayoutEffect(() => {
        if (data !== null)
            setValues(data);
    }, [data])

    return (
        <DashboardLayout>
            <div className="content-wrap content-wrap-70 mt-5" style={{ marginTop: 40 }}>
                <h5 className="text-black-50">Site Basic Settings</h5>

                <Paper elevation={3} sx={{ mt: 1, p: 5 }}>

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                        <div className="col-md-12">
                            <TextField value={values?.siteName} onChange={(e) => { setValues({ ...values, siteName: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Site Name" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.sitePhone} onChange={(e) => { setValues({ ...values, sitePhone: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Site Phone Number" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.siteUrl} onChange={(e) => { setValues({ ...values, siteUrl: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Site Url" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.siteEmail} onChange={(e) => { setValues({ ...values, siteEmail: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Site Email" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.siteEmail} onChange={(e) => { setValues({ ...values, siteEmail: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Site Send Notify Email" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.refPercentage} onChange={(e) => { setValues({ ...values, refPercentage: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Referral Percentage" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.address} onChange={(e) => { setValues({ ...values, address: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Wallet address" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.siteCode} onChange={(e) => { setValues({ ...values, siteCode: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Site Short Code Name" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.location} onChange={(e) => { setValues({ ...values, location: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Location" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.videoLink} onChange={(e) => { setValues({ ...values, videoLink: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Video Link" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.depositInvestmentCharge} onChange={(e) => { setValues({ ...values, depositInvestmentCharge: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Deposit Charge" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.withdrawCharge} onChange={(e) => { setValues({ ...values, withdrawCharge: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Withdraw Charge" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <TextField value={values?.blockIoPin} onChange={(e) => { setValues({ ...values, blockIoPin: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" placeholder="Block IO Secrent Pin" variant="standard" />
                        </div>

                        <div className="col-md-12">
                            <LoadingButton
                                color="primary"
                                onClick={() => SaveData()}
                                loading={isBusy}
                                disabled={isBusy}
                                loadingPosition="start"
                                startIcon={<SaveAlt />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </div>

                    </div>

                </Paper>

            </div>
            <CustomAlert ref={alertRef}>{messages}</CustomAlert>
        </DashboardLayout>
    )
}

export default BasicSettings;