import Swal from 'sweetalert2'

const LoadScript = () => {
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/js/jquery-3.2.1.min.js`;
    script.src = `${process.env.PUBLIC_URL}/styles/bootstrap4/popper.js`;
    script.src = `${process.env.PUBLIC_URL}/styles/bootstrap4/bootstrap.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/TweenMax.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/TimelineMax.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/scrollmagic/ScrollMagic.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/animation.gsap.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/greensock/ScrollToPlugin.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/OwlCarousel2-2.2.1/owl.carousel.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/easing/easing.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/video-js/video.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/video-js/Youtube.min.js`;
    script.src = `${process.env.PUBLIC_URL}/plugins/parallax-js-master/parallax.min.js`;
    script.src = `${process.env.PUBLIC_URL}/js/custom.js`;
    script.async = true;
    document.body.appendChild(script);
}

const NotificationToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
})

const AlertToast = (text, icon, btnText, showConfirmButton) => {
    return Swal.fire({
        text: text,
        icon: icon,
        confirmButtonText: btnText,
        allowOutsideClick: false,
        showConfirmButton: showConfirmButton,
    })
}

const DelayDuration = 2000;
const TaskDelay = ms => new Promise(resolve => setTimeout(resolve, ms));
let api = "https://api.kapitol-investment.thehebrewsshelters.com/";
// let api = "https://localhost:44317/";
let BaseUrl = `${api}api/`;
let PathUrl = api;
let AuthKey = "lQ@OsBFth1N#YBCA^CMUr$78x3Zu+n0xdI364%0sUjW/j36KOulT-msD?25_efkGi";


let Currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const TruncateString = (length, value) => {
    let newValue = value !== null ? value?.substring(0, length) : "Start Chat";
    return newValue !== null && newValue?.length === value?.length ? newValue : `${newValue}...`
}

export {
    LoadScript,
    NotificationToast,
    AlertToast,
    DelayDuration,
    TaskDelay,
    BaseUrl,
    PathUrl,
    AuthKey,
    Currency,
    TruncateString
}