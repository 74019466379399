import { useLayoutEffect, useEffect, useRef, useState } from "react";
import { NavLink } from 'react-router-dom';
import * as Icons from "@mui/icons-material"

export default function Header() {

    const [isAuth, setIsAuth] = useState(false);

    const isAuthenticated = () => {
        var userName = localStorage.getItem('userName');
        if (userName === null || userName === undefined)
            setIsAuth(false)
        else
            setIsAuth(true)
    }

    useEffect(() => {
        isAuthenticated();
    }, [isAuthenticated])

    useLayoutEffect(() => {
        isAuthenticated();
    }, [isAuthenticated])

    return (
        <header className="header navbar-area">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <nav className="navbar navbar-expand-lg">
                            <NavLink className="navbar-brand text-red" to="/">
                                <img style={{ borderRadius: 100 }} width={40} height={40} src="/kapitol-investment-logo.png" alt='Logo' /> <b style={{ color: 'white' }}>Kapitol Investment</b>
                            </NavLink>
                            <div style={{ cursor: 'pointer' }} className='navbar-toggler' data-toggle='collapse'
                                data-target="#navbarSupportedContent" aria-controls='navbarSupportedContent'
                                aria-expanded='false' aria-label="Toggle navigation">
                                <span className='toggler-icon'></span>
                                <span className='toggler-icon'></span>
                                <span className='toggler-icon'></span>
                            </div>
                            <div className="collapse navbar-collapse sub-menu-bar" id='navbarSupportedContent'>
                                <ul id='nav' className="navbar-nav ml-auto">
                                    <li className='nav-item active'>
                                        <NavLink to="/">Home</NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink to="/about">About</NavLink>
                                        {/* <a className='page-scroll' href="#about">About</a> */}
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink to="/investment-plans">Investment Plans</NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink to="/contact">Contact</NavLink>
                                    </li>
                                    {
                                        !isAuth ?
                                            <>
                                                <li className='nav-item'>
                                                    <NavLink to="/account/signup">Signup</NavLink>
                                                </li>
                                                <li className='nav-item'>
                                                    <NavLink to="/account/login">Login</NavLink>
                                                </li>
                                            </>
                                            :
                                            <li className='nav-item'>
                                                <NavLink to="/dashboard">Dashboard</NavLink>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <iframe style={{ height: '30px', width: '100%' }} title="grid data" src="https://www.exchangerates.org.uk/widget/ER-LRTICKER.php?w=auto&amp;s=1&amp;mc=GBP&amp;mbg=FFFFFF&amp;bs=no&amp;bc=000044&amp;f=helvetica&amp;fs=11px&amp;fc=000044&amp;lc=19335C&amp;lhc=faa31c&amp;vc=FE9A00&amp;vcu=008000&amp;vcd=FF0000&amp;" width="100" frameborder="0" scrolling="no" marginwidth="0" marginheight="0"></iframe>
        </header>
    )
}