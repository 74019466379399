import DashboardLayout from "../Shared/DashboardLayout";
import Moment from 'react-moment';
import { AccountBalanceWallet } from '@mui/icons-material';
import { useGetTransactionHistoryQuery } from "../Middlewares/Redux/transactionApi"
import { useLayoutEffect } from 'react';

export default function ManageTransactionHistory() {

    const { data, refetch, status } = useGetTransactionHistoryQuery();

    useLayoutEffect(() => {

    }, [data])

    return (
        <DashboardLayout>
            <div className="content-wrap content-wrap-100">
                <div className="row dashboard-card">

                    <div className="col-md-12"> <h3>TRANSACTION HISTORY</h3> </div>

                    <div className="col-md-12 dashboard-card-total">
                        <div className="card" style={{ color: 'white' }}>
                            <div className="card-body">
                                <ul className="report-list list-unstyled">

                                    {
                                        data?.map((item, index) => {
                                            return (
                                                <li key={index} className="report-item   report-danger ">
                                                    <div className="report-icon">
                                                        <AccountBalanceWallet color='action' />
                                                    </div>
                                                    <div className="report-text">
                                                        {item.Actions}
                                                        <span>{item.Descriptions}</span>
                                                    </div>
                                                    <div className="report-helper"> <Moment fromNow>{item.ActionDate}</Moment></div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}