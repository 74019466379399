import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomDialog from "../Utils/CustomDialog"
import CustomAlert from '../Utils/CustomAlert';
import DashboardLayout from '../Shared/DashboardLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete } from "@mui/icons-material";
import Moment from 'react-moment';
import * as common from "../Utils/Common";
import { useGetInvestmentsQuery, useConfirmInvestmentMutation } from "../Middlewares/Redux/investApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ManageDeposits() {
    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);
    const { data, refetch, status } = useGetInvestmentsQuery();
    const [confirmInvestment] = useConfirmInvestmentMutation();

    const alertRef = useRef(null);
    const dialogRef = useRef(null);

    const OnDeleteRequest = (Id, UserId, PlanId, Amount) => {
        let newMessage = `${UserId}'s deposit for ${PlanId} with amount: ${common.Currency.format(Amount)} will be deleted. \n\nAre you sure?`;
        setMessages(newMessage);
        dialogRef.current.toggleDialog();
    }

    const OnDelete = async () => {
        setBusy(true);

        common.TaskDelay(common.DelayDuration).then(async () => {
            setBusy(false);
            setMessages("Record deleted successfully");
            alertRef.current.toggleAlert();
        })
    }

    const ConfirmInvestment = (id, userId) => {
        setBusy(true);

        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await confirmInvestment(id, userId);
            setBusy(false);

            if (response !== null) {
                refetch();
            }

            setMessages(response?.data?.message);
            alertRef.current.toggleAlert();
        })
    }

    let count = 1;

    return (
        <DashboardLayout>
            <div className='container' style={{ marginTop: '70px', marginBottom: '50px' }}>
                <div className='row mb-5'>
                    <div className='col-md-6'><h2>Manage Deposits</h2></div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S/N</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 100 }}>Transaction Id</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>User</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Plan</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Coin</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Amount</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Profits</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Status</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 200 }}>Date</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>End</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 200 }}>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {data?.map((row) => (
                                <StyledTableRow key={row.Id}>
                                    <StyledTableCell component="th" scope="row"> {count++}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.TransactionId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.UserId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.PlanId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> Bitcoin</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.Amount}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.Profits}</StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        {row.Status === "Pending" || row.Status === "Completed" ? row.Status : <Moment fromNow>{row.End}</Moment>}
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        <Moment fromNow>{row.ActionDate}</Moment>
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        <Moment fromNow>{row.End}</Moment>
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        {
                                            row.Status === "Pending" ?
                                                <>
                                                    <Delete onClick={() => OnDeleteRequest(row.Id, row.UserId, row.PlanId, row.Amount)} isDisabled={isBusy} style={{ margin: 5, cursor: 'pointer' }} color="error" />
                                                    <LoadingButton
                                                        color="success"
                                                        onClick={() => ConfirmInvestment(row.Id, row.UserId)}
                                                        // loading={isBusy}
                                                        disabled={isBusy}
                                                        loadingPosition="start"
                                                        variant="contained"
                                                        sx={{ my: 0, float: 'right', px: 2, py: 1 }}
                                                    >
                                                        <span>Confirm</span>
                                                    </LoadingButton>
                                                </>
                                                : null
                                        }
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>

                <CustomDialog ref={dialogRef} >
                    <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                    <div className='row'>
                        <div className='col-md-6'><button className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                        <div className='col-md-6 text-right'>
                            <LoadingButton
                                color="error"
                                onClick={() => OnDelete()}
                                loading={isBusy}
                                disabled={isBusy}
                                loadingPosition="start"
                                startIcon={<Delete />}
                                variant="contained"
                                sx={{ my: 2, float: 'right', px: 2, py: 1 }}
                            >
                                <span>Yes</span>
                            </LoadingButton>
                        </div>
                    </div>
                </CustomDialog>

                <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

            </div >
        </DashboardLayout >
    );
}