import { apiSlice } from './Config/BasicSetup'

export const transactionApi = apiSlice.injectEndpoints({
    reducerPath: "transactionApi",
    tagTypes: ['Transaction'],
    endpoints: (build) => ({

        getTransactionHistory: build.query({
            query: () => `transactionHistory/get-all`,
            invalidatesTags: ['Transaction']
        }),

        getUserTransactionHistory: build.query({
            query: () => `transactionHistory/get-user-transaction`,
            invalidatesTags: ['Transaction']
        }),

    }),
    overrideExisting: true,
})

export const {
    useGetTransactionHistoryQuery,
    useGetUserTransactionHistoryQuery,
} = transactionApi;