export let SignupModel = {
    Id:null,
    Username:null,
    Password:null,
    Name:null,
    Email:null,
    PhoneNumber:null,
    BitcoinWalletAddress:null,
    SecreteQuestion:null,
    SecreteQuestionAnswer:null,
    UserRole:null,
    Membership:null,
    ReferralId:null,
}