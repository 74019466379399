import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../authSlice";
import { plansSettingApi } from "../plansSettingApi";
import { investApi } from "../investApi";
import { basicSettingsApi } from "../basicSettingsApi";
import { coinSettingsApi } from "../coinSettingsApi";
import { compoundingApi } from "../compoundingApi";
import { mailingSystemApi } from "../mailingSystemApi";
import { accountApi } from "../accountApi";
import { transactionApi } from "../transactionApi";
import { walletApi } from "../walletApi";
import { walletAddressApi } from "../walletAddressApi";
import { withdrawalApi } from "../withdrawalApi";
import { dashboardDataReportApi } from "../dashboardDataReportApi";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [plansSettingApi.reducerPath]: plansSettingApi.reducer,
        [investApi.reducerPath]: investApi.reducer,
        [basicSettingsApi.reducerPath]: basicSettingsApi.reducer,
        [coinSettingsApi.reducerPath]: coinSettingsApi.reducer,
        [compoundingApi.reducerPath]: compoundingApi.reducer,
        [mailingSystemApi.reducerPath]: mailingSystemApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [transactionApi.reducerPath]: transactionApi.reducer,
        [walletApi.reducerPath]: walletApi.reducer,
        [walletAddressApi.reducerPath]: walletAddressApi.reducer,
        [withdrawalApi.reducerPath]: withdrawalApi.reducer,
        [dashboardDataReportApi.reducerPath]: dashboardDataReportApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(accountApi.middleware),
})