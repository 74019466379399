import { useState } from "react";
import MainLayout from "../Shared/Mainlayout";

export default function Contact() {
    const [isBusy, setIsBusy] = useState();

    const handleSubmit = (e) => {

        e.preventDefault();
        setIsBusy(true);

        setTimeout(() => {
            setIsBusy(false);
            alert("Message sent successfully, we'll get back to you")
        }, 1000);
    }

    return (
        <MainLayout>
            <section id="contact" className="contact-section pt-120 pb-105">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-xl-6 col-lg-7">
                            <div className="contact-wrapper mb-30">
                                <h2 className="mb-20 wow fadeInDown" data-wow-delay=".2s">Get in Touch</h2>
                                <form onSubmit={(e) => handleSubmit(e)} id="contact-form" className="contact-form">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <input required type="text" id="name" name="name" placeholder="Name" />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <input required type="email" id="email" name="email" placeholder="Email" />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <input required type="text" id="phone" name="phone" placeholder="Phone" />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <input required type="text" id="subject" name="subject" placeholder="Subject" />
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea required name="message" id="message" rows="5" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <button disabled={isBusy} type={isBusy ? "reset" : "submit"} className="theme-btn theme-btn-2">SEND MESSAGE</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-5">
                            <div className="contact-map mb-30">
                                <div className="map-canvas">
                                    <iframe className="map" id="gmap_canvas"
                                        src="https://maps.google.com/maps?q=2%20THE%20ESPLANADE%20PERTH%20WESTERN%20AUSTRALIA&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}