import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './Middlewares/Redux/Config/Store';
import { Provider } from 'react-redux';
import AppRoute from './Shared/AppRoute';
import PageLoading from './Shared/PageLoading';
import * as pages from "./Pages/Index";
import ProtectedRoutes from './Shared/ProtectedRoute';


const root = ReactDOM.createRoot(document.getElementById('base_content'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppRoute />
    </React.StrictMode>
  </Provider>
);
reportWebVitals();