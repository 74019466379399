import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DashboardCustomize, Settings, AttachMoney, AccountBalanceWallet, PeopleAlt, Event, Mail, Calculate, Logout, Person, NoteAdd, BarChart, Money } from '@mui/icons-material';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import * as model from "../Models/Index";
import { useLayoutEffect } from 'react';
// import * as dtos from "../Dtos/Index";
// import * as clients from "../Clients/Https/Index";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        // backgroundColor: '#44b700',
        backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const drawerWidth = 280;

export default function DashboardLayout({ children }) {

    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //       });
    // },[])
    // const { window } = props;

    const navigate = useNavigate();

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
    const [user, setUser] = useState(model.currentUser.CurrentUserModel);

    useEffect(() => {
        let response = localStorage.getItem('user');

        if (response !== null || response !== undefined) {
            setUser(JSON.parse(response));
        }
    }, [])

    const [openSettings, setOpenSettings] = useState(false);
    const [openActivities, setOpenActivities] = useState(false);

    const LogOut = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("userName");
        navigate("/");
        window.location.reload();
    }

    const drawer = (

        <List
            sx={{ width: '100%', maxWidth: 360, height: '100vh', minWidth: drawerWidth, backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)', color: 'white' }}
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)', color: 'white', padding: 20 }} component="div" id="nested-list-subheader">
                    <h5 style={{ color: 'white' }}>Dashboard</h5>
                </ListSubheader>
            }
        >
            <div className='align-items-center justify-content-center' style={{ width: '100%', padding: 10, backgroundColor: 'white', marginBottom: 30 }}>
                <div style={{ margin: "0 30%" }}>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <Avatar alt="avatar" src="/avatar.png" sx={{ width: 96, height: 96 }} />
                    </StyledBadge>
                </div>

                <h4 class="border-bottom-white text-dark text-center mt-2 mb-2"><b>{user?.fullName}</b></h4>
                <div class="text-dark border-bottom-white text-center"><b>{user?.userName}</b></div>
                <div class="mt-2 text-center">
                <Button className='text-center' onClick={() => LogOut()} variant='contained' color='error'>Logout</Button>
                </div>

                
            </div>

            <ListItemButton>
                <DashboardCustomize color='#000' style={{ marginRight: 10 }} />
                <ListItemText primary="Dashboard" onClick={() => navigate("/dashboard")} />
                {/* <NavLink className="text-white" aria-label="Dashboard" to="/" tag={Link}>Dashboard</NavLink> */}
            </ListItemButton>

            <Divider style={{ backgroundColor: 'white' }} />

            <ListItemButton>
                <AttachMoney color='#000' style={{ marginRight: 10 }} />
                <ListItemText primary="Invest" onClick={() => navigate("/dashboard/invest")} />
                {/* <NavLink className="text-white" aria-label="Invest" to="/dashboard/invest" tag={Link}>Invest</NavLink> */}
            </ListItemButton>

            <Divider style={{ backgroundColor: 'white' }} />
            <ListItemButton>
                <AccountBalanceWallet color='#000' style={{ marginRight: 10 }} />
                <ListItemText primary="Withdraw" onClick={() => navigate("/dashboard/withdrawal")} />
                {/* <NavLink className="text-white" aria-label="Withdraw" to="/dashboard/withdraw" tag={Link}>Withdraw</NavLink> */}
            </ListItemButton>
            <Divider style={{ backgroundColor: 'white' }} />

            {
                user?.userRole !== "Admin" ?
                    <>
                        <ListItemButton>
                            <Money color='#000' style={{ marginRight: 10 }} />
                            <ListItemText primary="Deposit" onClick={() => navigate("/dashboard/deposit")} />
                        </ListItemButton>
                        <Divider style={{ backgroundColor: 'white' }} />

                        <ListItemButton>
                            <BarChart color='#000' style={{ marginRight: 10 }} />
                            <ListItemText primary="Earning" onClick={() => navigate("/dashboard/earning")} />
                        </ListItemButton>
                        <Divider style={{ backgroundColor: 'white' }} />

                    </>
                    : null
            }

            <ListItemButton>
                <PeopleAlt color='#000' style={{ marginRight: 10 }} />
                <ListItemText primary="Referral" onClick={() => navigate("/dashboard/referral")} />
                {/* <NavLink className="text-white" aria-label="Referral" to="/dashboard/refferal" tag={Link}>Referral</NavLink> */}
            </ListItemButton>
            <Divider style={{ backgroundColor: 'white' }} />

            {
                user?.role === "Admin" ?
                    <>
                        <ListItemButton onClick={() => setOpenSettings(!openSettings)} style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>
                            <Settings style={{ marginRight: 15 }} />
                            <ListItemText primary="Website Settings" />
                            {openSettings ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSettings} timeout="auto" unmountOnExit>
                            <List component="div" style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Plan Settings" to="/dashboard/basic-settings" tag={Link}>Basic Settings</NavLink>
                                </ListItemButton>

                                <Divider style={{ backgroundColor: 'white' }} />

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Plan Settings" to="/dashboard/plan-settings" tag={Link}>Plan Settings</NavLink>
                                </ListItemButton>

                                <Divider style={{ backgroundColor: 'white' }} />

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Coins Setting" to="/dashboard/coin-settings" tag={Link}>Coins Setting</NavLink>
                                </ListItemButton>

                                <Divider style={{ backgroundColor: 'white' }} />

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Compounding Settings" to="/dashboard/compounding" tag={Link}>Compounding Settings</NavLink>
                                </ListItemButton>
                            </List>
                        </Collapse>

                        <ListItemButton onClick={() => setOpenActivities(!openActivities)} style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>
                            <Event style={{ marginRight: 15 }} />
                            <ListItemText primary="Manage Activity" />
                            {openActivities ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openActivities} timeout="auto" unmountOnExit>
                            <List component="div" style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Referral" to="/dashboard/manage-users" tag={Link}>Manage User</NavLink>
                                </ListItemButton>
                                <Divider style={{ backgroundColor: 'white' }} />

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Referral" to="/dashboard/manage-deposits" tag={Link}>Manage Deposit</NavLink>
                                </ListItemButton>

                                <Divider style={{ backgroundColor: 'white' }} />

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Referral" to="/dashboard/manage-withdrawal" tag={Link}>Manage Withdrawal</NavLink>
                                </ListItemButton>

                                <Divider style={{ backgroundColor: 'white' }} />

                                <ListItemButton>
                                    <NavLink className="text-white" aria-label="Referral" to="/dashboard/manage-transaction-history" tag={Link}>Manage Transaction</NavLink>
                                </ListItemButton>
                            </List>
                        </Collapse>

                        <ListItemButton style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>
                            <Calculate color='#000' style={{ marginRight: 10 }} />
                            <ListItemText primary="Compounding" onClick={() => navigate("/dashboard/compound-investment")} />
                            {/* <NavLink className="text-white" aria-label="Dashboard" to="/" tag={Link}>Compounding</NavLink> */}
                        </ListItemButton>
                        <Divider style={{ backgroundColor: 'white' }} />

                        <ListItemButton style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>
                            <Mail color='#000' style={{ marginRight: 10 }} />
                            <ListItemText primary="Mailing System" onClick={() => navigate("/dashboard/mailing-system")} />
                            {/* <NavLink className="text-white" aria-label="Invest" to="/dashboard/invest" tag={Link}>Mailing System</NavLink> */}
                        </ListItemButton>
                        <Divider style={{ backgroundColor: 'white' }} />
                    </>
                    : null
            }

            <ListItemButton style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>
                <Person color='#000' style={{ marginRight: 10 }} />
                <ListItemText primary="Profile" onClick={() => navigate("/dashboard/user-profile")} />
                {/* <NavLink className="text-white" aria-label="Withdraw" to="/dashboard/withdraw" tag={Link}>Profile</NavLink> */}
            </ListItemButton>
            <Divider style={{ backgroundColor: 'white' }} />

            <ListItemButton style={{ backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)' }}>
                <Logout color='#000' style={{ marginRight: 10 }} />
                <ListItemText primary="Sign Out" onClick={() => LogOut()} />
                {/* <NavLink className="text-white" aria-label="Referral" to="/dashboard/refferal" tag={Link}>Sign Out</NavLink> */}
            </ListItemButton>
            <Divider style={{ backgroundColor: 'white' }} />

        </List >
    );

    //const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>

            <AppBar
                elevation={1}
                position="fixed"
                sx={{
                    backgroundImage: 'linear-gradient(#365ae1 0%,#244ae2 22.16%,#1029a6 76.69%,#1029a6 100%)',
                    // backgroundColor: 'white',
                    color: 'white',
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div" className='d-flex flex-wrap'>
                        <Avatar alt="avatar" src="/kapitol-investment-logo.png" sx={{ width: 40, height: 40, margin: 0 }} />
                        <NavLink aria-label="Home" style={{ fontSize: 18, textTransform: 'uppercase', color: 'white' }} to="/" tag={Link}>Kapitol Investment</NavLink>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        width: drawerWidth,
                        overflowX: 'hidden',
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        height: '100vh',
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, },
                        overflowX: 'hidden',
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, mt: 7, bgcolor: 'rgb(249, 249, 251)', p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                {children}
            </Box>
        </Box>
    );
}