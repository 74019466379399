import React, { useState, useRef, useEffect, useCallback } from 'react';
import CustomModal from "../Utils/CustomModal"
import CustomAlert from '../Utils/CustomAlert';
import DashboardLayout from '../Shared/DashboardLayout';
import * as model from "../Models/Index"
import { Divider, MenuItem, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SaveSharp } from "@mui/icons-material";
import * as common from "../Utils/Common";
import { useNavigate } from "react-router-dom";
import { useGetSelectedUserWalletAddressMutation } from "../Middlewares/Redux/walletAddressApi";
import { useGetSelectedUserWalletMutation, useUpdateWalletAddressMutation, useFundWalletMutation } from "../Middlewares/Redux/walletApi";
import { useSaveWalletAddressMutation } from "../Middlewares/Redux/walletAddressApi";


export default function UserProfile() {

    const navigate = useNavigate();

    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);
    const [isUserWallet, setUserWallet] = useState(false);
    const [values, setValues] = useState(model.currentUser.CurrentUserModel);
    const [wallet, setWallet] = useState(model.walletDetail.WalletDetailModel);
    const [walletAddress, setWalletAddress] = useState([]);
    const [wAddress, setWAddress] = useState(model.walletAddress.WalletAddressModel);
    const [manageWallet, setManageWallet] = useState(model.manageFunds.ManageFundsModel);
    const [getSelectedUserWalletAddress] = useGetSelectedUserWalletAddressMutation();
    const [getSelectedUserWallet] = useGetSelectedUserWalletMutation();
    const [updateWalletAddress] = useUpdateWalletAddressMutation();
    const [fundWallet] = useFundWalletMutation();
    const [saveWalletAddress] = useSaveWalletAddressMutation();

    const getWalletAddress = async () => {
        let response = JSON.parse(localStorage.getItem('user'));
        let walletAddress = await getSelectedUserWalletAddress(response?.userName);
        setWalletAddress(walletAddress.data);
    }

    const getWalletBalance = useCallback(async () => {
        let response = JSON.parse(localStorage.getItem('user'));
        let wallet = await getSelectedUserWallet(response?.userName);
        setWallet(wallet.data);
    })

    useEffect(() => {
        let response = localStorage.getItem('user');
        if (response !== null) {
            setValues(JSON.parse(response));
        }

        getWalletAddress();

        getWalletBalance();

    }, [])

    const alertRef = useRef(null);
    const addWalletAddressRef = useRef(null);

    const OnOpenAddWalletAddress = () => {
        setBusy(false);
        setWAddress(model.walletAddress.WalletAddressModel);
        addWalletAddressRef.current.toggleModal()
    }

    const OnUpdateWalletAddress = async (value, walletId) => {
        await updateWalletAddress(value, walletId);
        await getWalletAddress();
    }

    const OnFundWallet = async () => {
        manageWallet.UserId = values?.userName;
        manageWallet.UserName = values?.userName;
        let response = await fundWallet(manageWallet);

        await getWalletBalance();
        setManageWallet(model.manageFunds.ManageFundsModel);

        setMessages(response?.data?.message);
        alertRef.current.toggleAlert();
        setUserWallet(!isUserWallet)
    }

    const SaveWalletAddress = async () => {
        setBusy(true);

        try {
            common.TaskDelay(common.DelayDuration).then(async () => {

                wAddress.UserId = values?.userName;

                let response = await saveWalletAddress(wAddress);
                await getWalletAddress();

                addWalletAddressRef.current.toggleModal();
                setBusy(false);
                setWAddress(model.walletAddress.WalletAddressModel);

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            })
        } catch (error) {
            setBusy(false);
            setMessages(error.message);
            alertRef.current.toggleAlert();
        }

    }

    return (
        <DashboardLayout>
            <div className="card content-wrap content-wrap-90">
                <div className="card-body">
                    <div className="">
                        <section className="content">
                            <div className="row">

                                <div className="col-xl-4 col-lg-5">

                                    <div className="box card card-body">
                                        <div className="box-body box-profile">
                                            <img className="rounded img-fluid mx-auto d-block max-w-150 bg-white" alt="avatar" src="/avatar.png" />

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="bg-danger p-3 mt-3 text-white">
                                                        <h4 className="media media-single p-1 text-white">
                                                            Wallet Balance: {wallet !== null ? common.Currency.format(wallet?.AvailableBalance) : common.Currency.format(0)}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-8 col-lg-7">
                                    <div className="box box-solid box-inverse box-dark mb-4">
                                        <div className="box-header with-border mb-4">
                                            <h3 className="box-title">Personal details</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <input type="hidden" name="_token" value="t8ZLbg1lpn1hVMeftywn9Q9wC7I8oWzBulHQt3ZT" />                                                            <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label active">Full Name</label>
                                                        <div className="col-sm-8">
                                                            <input id="first_name" name="full_name" value={values?.fullName} type="text" className="form-control" />

                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label active">UserName</label>
                                                        <div className="col-sm-8">
                                                            <input id="last_name" name="username" value={values?.userName} type="text" className="form-control" />

                                                        </div>
                                                    </div>


                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label active">Email Adress</label>
                                                        <div className="col-sm-8">
                                                            <input id="email" name="email" value={values?.email} type="email" className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label active">User Role</label>
                                                        <div className="col-sm-8">
                                                            <input value={values?.role} type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box box-solid box-inverse box-dark mt-4">
                                        <div className="box-header with-border mb-4">
                                            <h3 className="box-title">My Wallet</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        walletAddress?.map((item, index) => {
                                                            return (
                                                                <div key={index} className="form-group row">
                                                                    <label className="col-sm-3 col-form-label active">{item.coin}</label>
                                                                    <div className="col-sm-9">
                                                                        <input className="form-control" onChange={(e) => OnUpdateWalletAddress(e.target.value, item.id)} value={item.address} type="text" placeholder="Wallet address" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="form-group row">
                                                        <label className="col-sm-2 col-form-label"></label>
                                                        <div className="col-sm-10">

                                                            <button type="button" className=" btn btn-warning text-white waves-effect waves-light" onClick={() => OnOpenAddWalletAddress()}>
                                                                Add New Wallet
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        values?.role !== "Admin" ? null :
                                            <div className="box box-solid box-inverse box-dark mt-4">
                                                <div className="box-header with-border">
                                                    <h3 className="box-title">Manage Funds</h3>
                                                </div>
                                                <div className="box-body">
                                                    <div className="row">
                                                        <div className="col-12">

                                                            <div className="form-group row">

                                                                <label className="col-sm-4 col-form-label active">Select Wallet:</label>
                                                                <div className="col-sm-8">
                                                                    <select value={manageWallet.WalletType} onChange={(e) => setManageWallet({ ...manageWallet, WalletType: e.target.value })} className="form-control select2 w-p100 select2-hidden-accessible" aria-hidden="true">
                                                                        <option value="" selected="" disabled="">Choose Option</option>
                                                                        <option value="Bitcoin">Bitcoin</option>
                                                                        <option value="Other Wallet">Other Wallet</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label active">Select Fund Type:</label>
                                                                <div className="col-sm-8">
                                                                    <select value={manageWallet.FundType} onChange={(e) => setManageWallet({ ...manageWallet, FundType: e.target.value })} className="form-control select2 w-p100 select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                        <option value="" selected="" disabled="">Choose Option</option>
                                                                        <option value="Add">Add</option>
                                                                        <option value="Add">Substract</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label active">Send fund to:</label>
                                                                <div className="col-sm-8">
                                                                    <select value={manageWallet.SendFundTo} onChange={(e) => setManageWallet({ ...manageWallet, SendFundTo: e.target.value })} className="form-control select2 w-p100 select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                        <option value="" selected="" disabled="">Choose Option</option>
                                                                        <option value="Balance">Balance</option>
                                                                        <option value="Profits">Profits</option>
                                                                        <option value="ReferralBonus">Referral Bonus</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label active">Amount</label>
                                                                <div className="col-sm-8">
                                                                    <input value={manageWallet.Amount} onChange={(e) => setManageWallet({ ...manageWallet, Amount: e.target.value })} className="form-control" type="number" placeholder="Enter Amount" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label active"></label>
                                                                <div className="col-sm-8">
                                                                    <button onClick={() => OnFundWallet()} type="submit" className="btn btn-warning text-white waves-effect waves-light">
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            </div>

            <CustomModal ref={addWalletAddressRef} width={'600px'} height={'200px'}>
                <div className="modal-header d-flex flex-wrap align-items-center justify-content-between">
                    <h4>Add Wallet Address</h4>
                    <b className="close text-danger" onClick={() => addWalletAddressRef.current.toggleModal()}>&times;</b>
                </div>
                <Divider />

                <div classNameName="row p-3 d-flex align-content-center align-items-center justify-content-between">

                    <div classNameName="col-md-12 mt-4" style={{ width: '100%' }}>
                        <TextField sx={{ width: '100%' }} id="WalletType" label="Wallet Type" value={wAddress.Coin} onChange={(e) => setWAddress({ ...wAddress, Coin: e.target.value })} select>
                            <MenuItem value="Bitcoin">Bitcoin</MenuItem>
                            <MenuItem value="Other Wallet">Other Wallet</MenuItem>
                        </TextField>
                    </div>

                    <div classNameName="col-md-12 mt-4" style={{ width: '100%' }}>
                        <TextField value={wAddress.Address} onChange={(e) => setWAddress({ ...wAddress, Address: e.target.value })} label="Address" sx={{ width: '100%', my: 1 }} id="standard-basic" />
                    </div>

                    <div classNameName='text-right' style={{ float: 'right', width: '100%' }}>
                        <LoadingButton
                            color="primary"
                            onClick={() => SaveWalletAddress()}
                            loading={isBusy}
                            loadingPosition="start"
                            startIcon={<SaveSharp />}
                            variant="contained"
                            sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                        >
                            <span>Save</span>
                        </LoadingButton>
                    </div>

                </div>

            </CustomModal>

            <CustomAlert ref={alertRef}>{messages}</CustomAlert>
        </DashboardLayout>
    )
}