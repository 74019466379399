import Header from './Header';
import Footer from './Footer';

export default function MainLayout({children}) {

    return (
        <>
            <Header />
            <section style={{ minHeight: '100vh', height: '100%' }}>
                {children}
            </section>
            <Footer />
        </>
    );
}