import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { TextField, Paper, Box, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import * as models from "../../Models/Index";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonAddAlt from '@mui/icons-material/PersonAddAlt';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import * as common from "../../Utils/Common";
import CustomAlert from '../../Utils/CustomAlert';
import { useNavigate } from "react-router-dom";
import { handleSignup, getAuthError, getAuthStatus } from "../../Middlewares/Redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from '../../Shared/Mainlayout';

export default function Login() {

    // const isAuthenticated = () => {
    //     var userName = localStorage.getItem('userName');
    //     if (userName !== null || userName !== undefined)
    //         return navigate('/dashboard', { replace: true })
    // }

    // useEffect(() => {
    //     isAuthenticated();
    // }, [isAuthenticated])

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        // isAuthenticated();
    }, [])

    const [showPassword, setShowPassword] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const dispatch = useDispatch();

    const alertRef = useRef(null);
    const [messages, setMessages] = useState();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [values, setValues] = useState(models.login.LoginModel);

    const navigate = useNavigate();

    const onHandleLogin = async (e) => {
        try {
            e.preventDefault();

            setIsBusy(true)
            common.TaskDelay(common.DelayDuration).then(async () => {
                let response = await dispatch(handleSignup(values)).unwrap();
                setIsBusy(false);

                console.log("error:::", response)
                if (response !== undefined || response !== null && response?.statusCode === 200) {

                    // localStorage.setItem("user", JSON.stringify(response?.data))
                    // localStorage.setItem("token", response?.data?.token)
                    // localStorage.setItem("userName", response?.data?.userName)
                    return navigate("/account/login")
                } else {
                    setMessages(response?.message === undefined ? "\nAn error has occured, please check your internet connection and try again" : `\n${response?.message}`)
                    alertRef.current.toggleAlert()
                }
            })
        } catch (error) {
            setIsBusy(false);
            setMessages(error.message);
        }
    }

    return (
        <MainLayout>
            <section id="about" className="about-section pt-130 pb-20">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 display-image">
                            <div className="about-img mb-30">
                                <img src="/assets/img/countdown-img.svg" alt="image" className="wow fadeInLeft" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="section-title mb-10">
                                <h2 className="wow fadeInUp" data-wow-delay=".2s">Signup</h2>
                                <div className='mt-2 mb-2'><NavLink to="/account/login" tag={Link}>Already have an account? Login Instead!</NavLink></div>
                            </div>
                            <Paper className="about-content mb-30" sx={{ width: '100%', borderRadius: 2, padding: '80px', paddingTop: '20px' }} elevation={0}>
                                <Form onSubmit={onHandleLogin}>
                                    <TextField required={true} value={values.Name} onChange={(e) => { setValues({ ...values, Name: e.target.value }) }} label="Full Name" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                                    <TextField required={true} type="email" value={values.Email} onChange={(e) => { setValues({ ...values, Email: e.target.value }) }} label="Email" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                                    <TextField required={true} value={values.BitcoinWalletAddress} onChange={(e) => { setValues({ ...values, BitcoinWalletAddress: e.target.value }) }} label="Bitcoin Wallet Address" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                                    <TextField required={true} value={values.SecreteQuestion} onChange={(e) => { setValues({ ...values, SecreteQuestion: e.target.value }) }} label="Secrete Question" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                                    <TextField required={true} value={values.SecreteQuestionAnswer} onChange={(e) => { setValues({ ...values, SecreteQuestionAnswer: e.target.value }) }} label="Secrete Question Answer" sx={{ width: '100%', my: 1 }} id="standard-basic" variant="standard" />
                                    <TextField required={true} value={values.Username} onChange={(e) => { setValues({ ...values, Username: e.target.value }) }} sx={{ width: '100%', my: 1 }} id="standard-basic" label="Username" variant="standard" />

                                    <FormControl sx={{ width: '100%', mt: 2 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                        <Input
                                            required={true}
                                            id="standard-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => { setValues({ ...values, Password: e.target.value }) }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <LoadingButton
                                        type="submit"
                                        color="primary"
                                        //onClick={() => onPostLogin()}
                                        disabled={isBusy}
                                        loading={isBusy}
                                        loadingPosition="start"
                                        startIcon={<PersonAddAlt />}
                                        variant="contained"
                                        sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                    >
                                        <span>Signup</span>
                                    </LoadingButton>
                                </Form>
                            </Paper>
                        </div>
                    </div>
                </div>
            </section>
            <CustomAlert ref={alertRef}>{messages}</CustomAlert>
        </MainLayout>

    )
}