import { apiSlice } from './Config/BasicSetup'

export const coinSettingsApi = apiSlice.injectEndpoints({
    reducerPath: "coinSettingsApi",
    tagTypes: ['CoinSettings'],
    endpoints: (build) => ({

        getCoinSettings: build.query({
            query: () => `coinsettings/get-all`,
            invalidatesTags: ['CoinSettings']
        }),

        coinSettingsFormData: build.mutation({
            query: (data) => ({
                url: `coinsettings/form-data`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['CoinSettings']
        }),

        coinSettingsToggle: build.mutation({
            query: (id) => ({
                url: `coinsettings/toggle?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['CoinSettings']
        }),

        coinSettingsDelete: build.mutation({
            query: (id) => ({
                url: `coinsettings/delete?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['CoinSettings']
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetCoinSettingsQuery,
    useCoinSettingsFormDataMutation,
    useCoinSettingsToggleMutation,
    useCoinSettingsDeleteMutation,
} = coinSettingsApi;