import { apiSlice } from './Config/BasicSetup'

export const basicSettingsApi = apiSlice.injectEndpoints({
    reducerPath: "basicSettingsApi",
    tagTypes: ['BasicSettings'],
    endpoints: (build) => ({

        getBasicSettings: build.query({
            query: () => `basicsettings/get-setting`,
            invalidatesTags: ['BasicSettings']
        }),

        basicSettingsFormData: build.mutation({
            query: (data) => ({
                url: `basicsettings/form-data`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['BasicSettings']
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetBasicSettingsQuery,
    useBasicSettingsFormDataMutation,
} = basicSettingsApi;