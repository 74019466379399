export let BasicSettingsModel = {
    id:null,
    siteName: null,
    sitePhone: null,
    siteUrl: null,
    siteEmail: null,
    refPercentage: null,
    address: null,
    siteCode: null,
    location: null,
    videoLink: null,
    copyRight: null,
    minWithdraw: null,
    depositInvestmentCharge:null,
    withdrawCharge: null,
    blockIoPin: null,
    logo: null,
    mode: null,
    autoWithdraw: null,
    emailBody: null,
}