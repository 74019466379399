import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomDialog from "../Utils/CustomDialog"
import CustomAlert from '../Utils/CustomAlert';
import DashboardLayout from '../Shared/DashboardLayout';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete } from "@mui/icons-material";
import Moment from 'react-moment';
import * as common from "../Utils/Common";
import { useGetWithdrawalRequestQuery, useConfirmWithdrawalRequestMutation } from "../Middlewares/Redux/withdrawalApi"
import {useFundUserBitcoinWalletMutation} from "../Middlewares/Redux/walletApi"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ManageWithdrawal() {
    const [messages, setMessages] = useState();
    const [isBusy, setBusy] = useState(false);
    const {data, refetch, status} = useGetWithdrawalRequestQuery();
    const [confirmWithdrawalRequest] = useConfirmWithdrawalRequestMutation();
    const [fundUserBitcoinWallet] = useFundUserBitcoinWalletMutation();
    const alertRef = useRef(null);
    const dialogRef = useRef(null);

    useLayoutEffect(() => {
    }, [data])

    let count = 1;

    const OnConfirmWithdrawalRequest = async (id) => {
        setBusy(true);
        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await confirmWithdrawalRequest(id);
            if (response !== null) {
                refetch();

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            }
            setBusy(false);
        })

    }

    const OnFundUserBitcoinWallet = async (id) => {
        setBusy(true);
        common.TaskDelay(common.DelayDuration).then(async () => {
            let response = await fundUserBitcoinWallet(id);
            if (response !== null) {
                refetch();

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            }
            setBusy(false);
        })
    }

    return (
        <DashboardLayout>
            <div className='container' style={{ marginTop: '50px' }}>
                <div className='row mb-5'>
                    <div className='col-md-6'><h2>Manage Withdrawals</h2></div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">

                        <TableHead>
                            <TableRow>
                                <StyledTableCell>S/N</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Transaction Id</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>User</StyledTableCell>
                                <StyledTableCell>Coin</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 100 }}>Amount</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 100 }}>Charge</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Amount to Pay</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Address</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Withdraw from</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Date</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }}>Status</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 220 }}>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data?.map((row) => (
                                <StyledTableRow key={row.Id}>
                                    <StyledTableCell component="th" scope="row"> {count++}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.TransactionId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.UserId}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> Bitcoin</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {common.Currency.format(row.Amount)}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {common.Currency.format(row.Charge)}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {common.Currency.format(row.AmountToPay)}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.BitcoinAddress}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row"> {row.WithdrawalType}</StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        <Moment fromNow>{row.ActionDate}</Moment>
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        {
                                            row.Status === "Funded" || row.Status === "Confirmed" ? <b class="text-success">{row.Status}</b> : <b class="text-danger">{row.Status}</b>
                                        }
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        {
                                            row.Status === "Pending" ?
                                                <>
                                                    <Delete isDisabled={isBusy} style={{ margin: 5, cursor: 'pointer' }} color="error" />

                                                    <LoadingButton
                                                        color="success"
                                                        onClick={() => OnConfirmWithdrawalRequest(row.Id)}
                                                        // loading={isBusy}
                                                        disabled={isBusy}
                                                        loadingPosition="start"
                                                        variant="contained"
                                                        sx={{ my: 0, float: 'right', px: 2, py: 1 }}
                                                    >
                                                        <span>Confirm</span>
                                                    </LoadingButton>
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            row.Status === "Confirmed" ?
                                                <LoadingButton
                                                    color="primary"
                                                    onClick={() => OnFundUserBitcoinWallet(row.Id)}
                                                    // loading={isBusy}
                                                    disabled={isBusy}
                                                    loadingPosition="start"
                                                    variant="contained"
                                                    sx={{ my: 0, float: 'right', px: 2, py: 1 }}
                                                >
                                                    <span>Transfer</span>
                                                </LoadingButton>
                                                : null
                                        }

                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>

                <CustomDialog ref={dialogRef} >
                    <h4 className='text-success text-center mb-5 mt-3'>{messages}</h4>
                    <div className='row'>
                        <div className='col-md-6'><button className='btn btn-default' onClick={() => dialogRef.current.toggleDialog()}>No</button></div>
                        {/* <div className='col-md-6 text-right'><button className='btn btn-danger' onClick={OnDelete}>Yes</button></div> */}
                    </div>
                </CustomDialog>

                <CustomAlert ref={alertRef}><h3 className='text-center'> {messages}</h3></CustomAlert>

            </div >
        </DashboardLayout >
    );
}