import { useState, useRef } from "react"
import { TextField, MenuItem, Paper } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DashboardLayout from "../Shared/DashboardLayout";
import * as model from "../Models/Index";
import { Mail } from "@mui/icons-material";
import * as common from "../Utils/Common";
import CustomAlert from '../Utils/CustomAlert';
import { useMaillingSystemSendMailMutation } from "../Middlewares/Redux/mailingSystemApi";
import { useGetUsersQuery } from "../Middlewares/Redux/accountApi";

const MailingSystem = () => {

    const [values, setValues] = useState(model.mailingSystem.MailingSystemModel);
    const [isBusy, setBusy] = useState(false);
    const { data, refetch, status } = useGetUsersQuery();
    const [maillingSystemSendMail] = useMaillingSystemSendMailMutation();

    const [messages, setMessages] = useState();
    const alertRef = useRef(null);

    const SendMail = async () => {
        try {
            setBusy(true);

            common.TaskDelay(common.DelayDuration).then(async () => {
                let response = await maillingSystemSendMail(values);
                setBusy(false);
                if (response !== null) {
                    setValues(model.mailingSystem.MailingSystemModel);
                }
                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            })
        } catch (error) {
            setMessages(error.message);
            alertRef.current.toggleAlert();
        }
    }

    return (
        <DashboardLayout>
            <div className="content-wrap content-wrap-90" style={{ marginTop: 40 }}>
                <h5 className="text-black-50">MAILLING SYSTEM</h5>
                <Paper elevation={3} sx={{ mt: 1, p: 5 }}>
                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">
                        <div className="col-md-12">

                            <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                                <TextField value={values.Username} onChange={(e) => setValues({ ...values, Username: e.target.value })} sx={{ width: '100%' }} id="users" label="Users" select>
                                    {
                                        data?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.UserName}>{item.Name} ({item.UserName})</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </div>

                            <div className="col-md-12">
                                <TextField value={values.Title} onChange={(e) => setValues({ ...values, Title: e.target.value })} sx={{ width: '100%', my: 3 }} id="standard-basic" label="Title" />
                            </div>

                            <div className="col-md-12">
                                <TextField multiline={true} rows={4} value={values.Message} onChange={(e) => setValues({ ...values, Message: e.target.value })} sx={{ width: '100%', my: 3 }} id="standard-basic" label="Message" />
                            </div>

                            <div className="col-md-12">
                                <LoadingButton
                                    color="primary"
                                    onClick={() => SendMail()}
                                    loading={isBusy}
                                    disabled={isBusy}
                                    loadingPosition="start"
                                    startIcon={<Mail />}
                                    variant="contained"
                                    sx={{ my: 2, float: 'right', px: 5, py: 1 }}
                                >
                                    <span>Send</span>
                                </LoadingButton>
                            </div>

                        </div>

                    </div >
                </Paper>

            </div>

            <CustomAlert ref={alertRef}>{messages}</CustomAlert>

        </DashboardLayout>
    )
}

export default MailingSystem;