import { apiSlice } from './Config/BasicSetup'

export const walletApi = apiSlice.injectEndpoints({
    reducerPath: "walletApi",
    tagTypes: ['Wallet'],
    endpoints: (build) => ({

        getWallet: build.query({
            query: () => `wallet/get-all`,
            invalidatesTags: ['Wallet']
        }), 

        getUserWallet: build.query({
            query: () => `wallet/get-user-wallet`,
            invalidatesTags: ['Wallet']
        }),

        // getSelectedUserWallet: build.query({
        //     query: (userId) => `wallet/get-user-wallet-detail?userId=${userId}`,
        //     invalidatesTags: ['Wallet']
        // }),

        getSelectedUserWallet: build.mutation({
            query: (userId) => ({
                url: `wallet/get-user-wallet-detail?userId=${userId}`,
                method: 'GET',
            }),
            invalidatesTags: ['Wallet']
        }),

        fundWallet: build.mutation({
            query: (data) => ({
                url: `wallet/manage-fund-wallet`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['Wallet']
        }),

        fundUserBitcoinWallet: build.mutation({
            query: (id) => ({
                url: `wallet/fund-user-bitcoin-wallet?withdrawalId=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Wallet']
        }),

        updateWalletAddress: build.mutation({
            query: (address, walletId) => ({
                url: `wallet/update-wallet-address?address=${address}&walletId=${walletId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Wallet']
        }),
    }),
    overrideExisting: true,
})
  
export const {
    useGetWalletQuery,
    useGetUserWalletQuery,
    useGetSelectedUserWalletMutation,
    useFundWalletMutation,
    useFundUserBitcoinWalletMutation,
    useUpdateWalletAddressMutation,
} = walletApi;