import { apiSlice } from './Config/BasicSetup'

export const compoundingApi = apiSlice.injectEndpoints({
    reducerPath: "compoundingApi",
    tagTypes: ['Compounding'],
    endpoints: (build) => ({

        getCompounding: build.query({
            query: () => `compounding/get-all`,
            invalidatesTags: ['Compounding']
        }),

        compoundingFormData: build.mutation({
            query: (data) => ({
                url: `compounding/form-data`,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['Compounding']
        }),

        compoundingToggle: build.mutation({
            query: (id) => ({
                url: `compounding/toggle?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Compounding']
        }),

        compoundingDelete: build.mutation({
            query: (id) => ({
                url: `compounding/delete?id=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Compounding']
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetCompoundingQuery,
    useCompoundingFormDataMutation,
    useCompoundingToggleMutation,
    useCompoundingDeleteMutation,
} = compoundingApi;