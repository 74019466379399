import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const user = { loggedIn: localStorage.getItem("token") };
    return user && user.loggedIn;
};

export default function ProtectedRoutes() {
    const isAuth = useAuth();
    return !isAuth ? <Navigate to="/account/login" /> : <Outlet />;
};