import { apiSlice } from './Config/BasicSetup'

export const accountApi = apiSlice.injectEndpoints({
    reducerPath: "accountApi",
    tagTypes: ['Account'],

    endpoints: (build) => ({

        getUsers: build.query({
            query: () => 'account/get-users',
            providesTags:['Account']
        }),

        getUserRole: build.query({
            query: () => `account/get-user-role`,
            providesTags: ['Account']
        }),
    
        verifyAccount: build.mutation({
            query: (username, password) => ({
                url: `account/VerifyAccount?Username=${username}&Password=${password}`,
                method: 'POST',
            }),
            providesTags:['Account']
        }),

        toggleUser: build.mutation({
            query: (userId) => ({
                url: `account/toggle-user?id=${userId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Account']
        }),

        deleteUser: build.mutation({
            query: (userId) => ({
                url: `account/delete-user?id=${userId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Account']
        }),

        changePassword: build.mutation({
            query: (data) => ({
                url: `account/change-password`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Account']
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetUsersQuery,
    useGetUserRoleQuery,
    useVerifyAccountMutation,
    useToggleUserMutation,
    useDeleteUserMutation,
    useChangePasswordMutation,
} = accountApi;