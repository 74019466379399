import { useEffect, useState, useRef } from "react"
import { TextField, MenuItem, Paper } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AttachMoneySharp from '@mui/icons-material/AttachMoneySharp';
import DashboardLayout from "../Shared/DashboardLayout";
import * as common from "../Utils/Common";
import * as model from "../Models/Index";
import CustomAlert from '../Utils/CustomAlert';
import { useGetSelectedUserWalletAddressMutation } from "../Middlewares/Redux/walletAddressApi";
import { useGetSelectedUserWalletMutation, useUpdateWalletAddressMutation, useFundWalletMutation } from "../Middlewares/Redux/walletApi";
import { useWithdrawalMutation } from "../Middlewares/Redux/withdrawalApi";


export default function Withdrawal() {

    const [walletDetail, setWalletDetail] = useState(model.walletDetail.WalletDetailModel);
    const [withdraw, setWithdraw] = useState(model.withdrawal.WithdrawalModel);
    const [values, setValues] = useState(model.currentUser.CurrentUserModel);
    const [isBusy, setBusy] = useState(false);
    const [walletAddress, setWalletAddress] = useState([]);
    const [messages, setMessages] = useState();
    const [getSelectedUserWalletAddress] = useGetSelectedUserWalletAddressMutation();
    const [getSelectedUserWallet] = useGetSelectedUserWalletMutation();
    const [updateWalletAddress] = useUpdateWalletAddressMutation();
    const [fundWallet] = useFundWalletMutation();
    const [withdrawal] = useWithdrawalMutation();
    const alertRef = useRef(null);

    const getUser = () => {
        let response = localStorage.getItem('user');
        if (response !== null) {
            setValues(JSON.parse(response));
        }
    }

    const getWalletBalance = async () => {
        let response = JSON.parse(localStorage.getItem('user'));

        let wallet = await getSelectedUserWallet(response?.id);
        setWalletDetail(wallet?.data);
    }

    const getWalletAddress = async () => {
        let response = JSON.parse(localStorage.getItem('user'));

        let walletAddress = await getSelectedUserWalletAddress(response?.userName);
        setWalletAddress(walletAddress?.data);
    }

    useEffect(() => {

        getUser();

        //alert(values.id)

        getWalletBalance();

        getWalletAddress();

    }, [])


    const MakeRequest = async () => {

        try {
            setBusy(true);
            let response = JSON.parse(sessionStorage.getItem('user'));

            withdraw.UserId = response?.id;

            common.TaskDelay(common.DelayDuration).then(async () => {
                const response = await withdrawal(withdrawal);
                console.info("response: ", response)
                if (response !== null && response.statusCode === 200) {
                    setWithdraw(model.withdrawal.WithdrawalModel);
                    await getWalletBalance();
                }
                setBusy(false);

                setMessages(response?.data?.message);
                alertRef.current.toggleAlert();
            })
        } catch (error) {
            setBusy(false);
            alert(error.message);
        }
    }

    return (
        <DashboardLayout>
            <div className="content-wrap content-wrap-90" style={{ marginTop: 40 }}>
                <h5 className="text-black-50">WITHDRAWAL</h5>

                <Paper elevation={3} sx={{ mt: 1, p: 5 }}>

                    <div className="row p-3 d-flex align-content-center align-items-center justify-content-between">

                        <button className="col-md-3 withdrawal-card bg-success text-white"><h6 className="text-white">AVAILABLE BALANCE <br /> {walletDetail ? common.Currency.format(walletDetail?.AvailableBalance) : common.Currency.format(0)}</h6></button>
                        <button className="col-md-3 withdrawal-card bg-info text-white"><h6 className="text-white">BALANCE <br /> {walletDetail ? common.Currency.format(walletDetail?.Balance) : common.Currency.format(0)}</h6></button>
                        <button className="col-md-3 withdrawal-card bg-secondary text-white"><h6 className="text-white">PROFIT <br /> {walletDetail ? common.Currency.format(walletDetail?.Profits) : common.Currency.format(0)}</h6></button>
                        <button className="col-md-3 withdrawal-card bg-dark text-white" > <h6 className="text-white">REFERRAL BONUS <br /> {walletDetail ? common.Currency.format(walletDetail?.ReferralBonus) : common.Currency.format(0)}</h6></button >

                        <div className="col-md-12">
                            <p className="mt-3 mb-3 text-center">
                                You will be charged $5.00 in every Withdrawal.
                            </p>

                            <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                                <TextField sx={{ width: '100%' }} id="WithdrawalTypes" label="Withdrawal Types" value={withdraw.WithdrawalType} onChange={(e) => setWithdraw({ ...withdraw, WithdrawalType: e.target.value })} select>
                                    <MenuItem value="AllFunds">All Funds</MenuItem>
                                    <MenuItem value="Balance">Balance</MenuItem>
                                    <MenuItem value="Profit">Profit</MenuItem>
                                    <MenuItem value="ReferralBonus">Referral Bonus</MenuItem>
                                </TextField>
                            </div>

                            <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                                <TextField value={withdraw.Amount} onChange={(e) => setWithdraw({ ...withdraw, Amount: e.target.value })} sx={{ width: '100%' }} id="standard-basic" label="Enter Amount" />
                            </div>

                            <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                                <TextField value={withdraw.WalletType} onChange={(e) => setWithdraw({ ...withdraw, WalletType: e.target.value })} sx={{ width: '100%' }} id="WalletType" label="Wallet Type" select>
                                    <MenuItem value="Bitcoin">Bitcoin</MenuItem>
                                    <MenuItem value="Other Wallet">Other Wallet</MenuItem>
                                </TextField>
                            </div>

                            <div className="col-md-12 mt-4" style={{ width: '100%' }}>
                                <TextField value={withdraw.BitcoinAddress} onChange={(e) => setWithdraw({ ...withdraw, BitcoinAddress: e.target.value })} sx={{ width: '100%' }} id="WithdrawalTypes" label="Crypto Wallet" select>
                                    {
                                        walletAddress?.filter(x => x.coin == withdraw.WalletType)?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item?.address}>{item?.coin}: {item?.address}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </div>


                            <div className="col-md-12">
                                <LoadingButton
                                    color="warning"
                                    onClick={() => MakeRequest()}
                                    loading={isBusy}
                                    disabled={isBusy}
                                    loadingPosition="start"
                                    startIcon={<AttachMoneySharp />}
                                    variant="contained"
                                    sx={{ my: 2, float: 'right', px: 5, py: 2 }}
                                >
                                    <span>Make a Request</span>
                                </LoadingButton>
                            </div>

                        </div>

                    </div >
                </Paper>
            </div>

            <CustomAlert ref={alertRef}>{messages}</CustomAlert>

        </DashboardLayout>
    )
}